// TODO: rename language to locale
export const CURRENCIES = {
  us: {
    currency: "USD",
    language: {
      en: "en-US",
    },
  },
  ca: {
    currency: "CAD",
    language: {
      en: "en-CA",
      fr: "fr-CA",
    },
  },
  mx: {
    currency: "MXN",
    language: {
      en: "en-US",
      es: "es-MX",
    },
  },
  eu: {
    currency: "EUR",
    language: {
      fr: "fr-FR",
      es: "es-ES",
      el: "el-GR",
      nl: "nl-NL",
      it: "it-IT",
      de: "de-DE",
      sl: "sl-SI",
    },
  },
  ch: {
    currency: "CHF",
    language: {
      fr: "fr-CH",
      de: "de-CH",
      en: "en-US",
    },
  },
  mt: {
    currency: "EUR",
    language: {
      en: "en-US",
    },
  },
  hr: {
    currency: "EUR",
    language: {
      en: "en-US",
    },
  },
  gb: {
    currency: "GBP",
    language: {
      en: "en-GB",
    },
  },
  si: {
    currency: "EUR",
    language: {
      en: "en-US",
    },
  },
  in: {
    currency: "INR",
    language: {
      en: "en-US",
    },
  },
  ae: {
    currency: "AED",
    language: {
      en: "en-US",
    },
  },
  pt: {
    currency: "EUR",
    language: {
      en: "pt-PT",
      pt: "pt-PT",
    },
  },
};
