// Profile logs color: #cf1019
import moment from "moment";
import _ from "lodash";
import config from "@/config";

import {
  TICKETS_COOKIE_NAME,
  getCookie,
  setCookie,
  deleteAllCookies,
} from "@/cookie.js";
import { loadLanguageAsync } from "../../i18n.js";
import { Localizer, pushDataLayerEvent } from "@/common/helpers";

const API = config.apiGate; //

function initializeHotjar(userData) {
  let userId = userData.id;
  if (window && window.hj)
    // console.log("HJ init", userData.id, userData.spartan_plus_member);
    window.hj("identify", userId, {
      spartan_plus_member: userData.spartan_plus_member,
    });
}

export default {
  state: {
    user: null,
    profileBackup: null,
    accountBackup: null,
    addressBackup: null,
    isAuthorized: false,
    userPic: {},
    isLoaded: {
      avatarImage: false,
      translations: false,
      userData: false,
      userLocaleTranslations: false,
    },
    headerLinks: null,
    sidebarLinks: null,
    domain: {
      home: config.homePage,
      search: config.searchUrl,
      findRace: config.findRaceUrl,
      shop: config.shopUrl,
      authPage: config.authPage,
      orders: config.ordersUrl,
    },
    language: "en",
    i18nLoadedPackage: null,
    i18nLoadedPackageList: {},
    userCodes: {
      active: [],
      expired: [],
    },
  },
  actions: {
    async getUserData(ctx) {
      ctx.commit("changeLoadedStatus", {
        statusName: "userData",
        status: false,
      });

      const authorization = getCookie("user");
      const res = await fetch(`${API}/account/athlete_users/`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      });
      if (!res.ok && res.status === 500)
        ctx.dispatch("handleToolbarNotification");
      if (!res.ok) {
        ctx.commit("logOutUser");
        return Promise.reject({ status: res.status, text: "Unauthorized" });
      }
      const userData = await res.json();

      if (userData.language) moment.locale(userData.language);
      // TODO: move all user data manipulations into one mutation
      ctx.commit("setAuthorizedStatus", true);
      ctx.commit("updateUser", userData);
      // ctx.commit("updateSubscriptionStatus", userData);
      ctx.commit("updateProfileBackup", userData);
      ctx.commit("updateAddressBackup", userData);
      ctx.commit("updateAccountBackup", userData);
      ctx.commit("updatePic", userData.avatar);
      const userLocale = `${
        userData?.language
      }-${userData?.spartan_country_code?.toUpperCase()}`;
      ctx.dispatch("getHeaderLinks", userLocale);
      initializeHotjar(userData);
      // user cart
      let cartToken = getCookie(TICKETS_COOKIE_NAME);
      try {
        await ctx.dispatch("getTranslations", userData.language);
        if (!cartToken && userData?.cart_token) {
          setCookie(TICKETS_COOKIE_NAME, userData.cart_token, 30);
          await ctx.dispatch("getCart");
        }
      } catch (err) {
        console.log(`%c${err.text}`, "color: #cf1019", err);
      }
      ctx.commit("changeLoadedStatus", {
        statusName: "userLocaleTranslations",
        status: true,
      });
      ctx.commit("changeLoadedStatus", {
        statusName: "userData",
        status: true,
      });
    },
    async logOut(ctx) {
      const authorization = getCookie("user");
      await fetch(`${API}/auth/athlete_users/sign_out`, {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: authorization,
        }),
      });
      pushDataLayerEvent("loggedOut", { userData: ctx.state.user });
      ctx.commit("logOutUser");
      deleteAllCookies();
    },
    async uploadPhoto(ctx, img) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/account/athlete_users`, {
        method: "PATCH",
        headers: new Headers({
          // 'Content-Type': 'application/json',
          Accept: "application/json",
          authorization: authorization,
        }),
        body: img,
      });
      if (!res.ok && res.status === 500)
        ctx.dispatch("handleToolbarNotification");
      if (res.status === 413) return { status: res.status };
      const uploadedUser = await res.json();
      // console.log("Uploaded user", uploadedUser)
      if (uploadedUser.error) return { status: 500 };
      ctx.commit("updateUser", uploadedUser);
      ctx.commit("updatePic", uploadedUser.avatar);
      return { status: 200 };
    },
    cancelProfileChanges(ctx) {
      ctx.commit("restoreProfileFromBackup");
    },
    cancelAddressChanges(ctx) {
      ctx.commit("restoreAddressFromBackup");
    },
    cancelAccountChanges(ctx) {
      ctx.commit("restoreAccountFromBackup");
    },
    async patchUserData(ctx, data) {
      const authorization = getCookie("user");
      const patchData = {
        athlete_user: data,
      };
      const res = await fetch(`${API}/account/athlete_users`, {
        method: "PATCH",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: authorization,
        }),
        body: JSON.stringify(patchData),
      });

      if (!res.ok && res.status === 500)
        ctx.dispatch("handleToolbarNotification");
      if (!res.ok) {
        let response = await res?.json();
        return Promise.reject({
          status: res.status,
          type:
            response?.email && response.email[0] === "taken"
              ? "email"
              : "server",
          text: "Update profile error",
        });
      }

      const userData = await res.json();

      // if (userData.email[0] === "taken")
      //   return Promise.reject({
      //     status: res.status,
      //     type: "email",
      //     text: userData.email[0],
      //   });

      if (userData.language) moment.locale(userData.language);
      ctx.commit("updateUser", userData);
      ctx.commit("updateProfileBackup", userData);
      ctx.commit("updateAddressBackup", userData);
      ctx.commit("updateAccountBackup", userData);
      try {
        await ctx.dispatch("getTranslations", userData.language);
      } catch (err) {
        console.log("%cPatch user error", "color: #cf1019", err);
      }
      return userData;
    },
    async patchUserDataWithLocale(ctx, data) {
      const userData = await ctx.dispatch("patchUserData", data);
      const userLocale = `${
        userData?.language
      }-${userData?.spartan_country_code?.toUpperCase()}`;
      ctx.dispatch("getHeaderLinks", userLocale);
    },
    async updateOnboarding(ctx, type) {
      // TODO: add different types support
      console.log(type);

      const authorization = getCookie("user");
      let data = {
        teams_onboarding: true,
      };
      const res = await fetch(`${API}/account/athlete_users/extra`, {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: authorization,
        }),
        body: JSON.stringify(data),
      });
      if (!res.ok) {
        return { msg: `${type} status onboarding failed` };
      }
      let result = await res.json();
      console.log(result);

      await ctx.dispatch("getUserData");
    },
    async getHeaderLinks(ctx, target_locale = "en-US") {
      const localeExceptions = [
        "es-PE",
        "en-PE",
        "en-NZ",
        "es-AD",
        "en-AD",
        "it-MT",
        "en-MT",
        "fr-LI",
        "de-LI",
        "en-LI",
        "en-GB",
      ];
      const exceptionsConfig = {
        // Peru
        "es-PE": "es-CL",
        "en-PE": "en-CL",
        // New Zealand
        "en-NZ": "en-AU",
        // Andorra
        "es-AD": "es-ES",
        "en-AD": "en-ES",
        // Malta
        "it-MT": "it-IT",
        "en-MT": "en-IT",
        // Liechtenstein
        "fr-LI": "fr-CH",
        "de-LI": "de-CH",
        "en-LI": "en-CH",
        // United Kingdom
        "en-GB": "en-UK",
      };

      const [lang, country] = target_locale.split("-");
      const locale = Localizer.resolveLocale(country, lang);
      let current_locale = `${locale.lang}-${locale.country}`;

      if (localeExceptions.includes(current_locale)) {
        current_locale = exceptionsConfig[current_locale];
      }

      const references = {
        Asset: {},
        Entry: {},
      };
      let menuItems = [];
      const contentfulConfig = config.contentful;
      const res = await fetch(
        `https://cdn.contentful.com/spaces/${contentfulConfig.spaceID}/environments/${contentfulConfig.environment}/entries?sys.id=${contentfulConfig.sysId}&include=6&locale=${current_locale}`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${contentfulConfig.serviceToken}`,
          }),
        }
      );

      if (!res.ok) {
        ctx.commit("updateHeaderLinks", null);
        return;
        // return Promise.reject({ status: res.status, text: "" });
      }

      const result = await res.json();

      // * parse references

      for (const ref in result.includes) {
        result.includes[ref].forEach((val) => {
          references[ref][val.sys.id] = val;
        });
      }

      menuItems = result.items[0].fields.components || [];

      ctx.commit("updateHeaderLinks", {
        menuItems,
        references,
      });
    },
    async getTranslations(ctx, lang = "en") {
      let { i18nLoadedPackage } = ctx.state;
      if (i18nLoadedPackage === lang) return;
      const res = await fetch(`${API}/account/localise/${lang}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      });
      if (!res.ok) {
        ctx.commit("updatei18nTranslations", null);
        return Promise.reject({
          status: res.status,
          text: "Translations load error",
        });
      }
      ctx.commit("updatei18nTranslations", lang);
      const translations = await res.json();
      ctx.commit("updatei18nLoadedList", translations);
      await loadLanguageAsync(lang, translations);
    },
    async changePassword(ctx, passwordData) {
      const authorization = getCookie("user");
      const data = {
        athlete_user: passwordData,
      };
      const response = await fetch(`${API}/account/athlete_users`, {
        method: "PATCH",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: authorization,
        }),
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (!response.ok || responseData["current_password"]) {
        return Promise.reject({
          status: response.status,
          statusText: response.statusText,
          data: {
            errors: {
              ...responseData,
              oldPassword: responseData.current_password,
            },
          },
        });
      }
      await ctx.dispatch("getUserData");
      return Promise.resolve(responseData);
    },
    async createPassword(ctx, passwordData) {
      const authorization = getCookie("user");
      const data = {
        athlete_user: {
          password: passwordData,
        },
      };
      const response = await fetch(`${API}/account/athlete_users`, {
        method: "PATCH",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: authorization,
        }),
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (!response.ok) {
        return Promise.reject({
          status: response.status,
          statusText: response.statusText,
          data: responseData,
        });
      }
      await ctx.dispatch("getUserData");
      return Promise.resolve(responseData);
    },
    loadAvatar(ctx) {
      ctx.commit("updateLoadImg");
    },
    async getMagazine(ctx, magazineId) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/athlete_users/magazine/${magazineId}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      });
      if (!res.ok) return Promise.reject("File not found");
      const magazineData = await res.json();
      return magazineData;
    },
    async getDocument(ctx, document) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/athlete_users/reading/${document}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      });
      if (!res.ok) return Promise.reject("File not found");
      const magazineData = await res.json();
      return magazineData;
    },
    async deleteOAuthConnection(ctx, id) {
      const authorization = getCookie("user");
      try {
        const response = await fetch(
          `${API}/account/oauth_authorizations/${id}`,
          {
            method: "DELETE",
            headers: new Headers({
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: authorization,
            }),
          }
        );

        if (!response.ok) {
          return Promise.reject(`Server error. Status: ${response.status}`);
        }
        return ctx.dispatch("getUserData");
      } catch (e) {
        console.error(e);
        return Promise.reject("Server error");
      }
    },
    async getCodes(ctx) {
      const authorization = getCookie("user");
      let { userData } = ctx.getters;
      const res = await fetch(`${API}/account/passes`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
          "Prefer-Language": `${userData.language || "en"}-${
            _.toUpper(userData.spartan_country_code) || "US"
          }`,
        }),
      });
      if (!res.ok && res.status === 500)
        ctx.dispatch("handleToolbarNotification");
      if (!res.ok) {
        return Promise.reject({
          type: "api-gate",
          status: res.status,
          text: "API codes error",
        });
      }
      const result = await res.json();
      ctx.commit("setUserCodes", result);
      // console.log("MY CODES", result);
      return result;
    },
  },
  mutations: {
    updateUser(state, user) {
      state.user = user;
      state.user.mobile_phone = state.user?.mobile_phone?.replace("+", "");
      state.user.emergency_contact_phone =
        state.user?.emergency_contact_phone?.replace("+", "");
      state.language = user.language;
    },
    setAuthorizedStatus(state, status) {
      state.isAuthorized = status;
    },
    updateProfileBackup(state, user) {
      state.profileBackup = {
        first_name: user.first_name,
        last_name: user.last_name,
        gender: user.gender,
        birth_date: user.birth_date,
        mobile_phone: user.mobile_phone,
        tshirt_size: user.tshirt_size,
        emergency_contact_name: user.emergency_contact_name,
        emergency_contact_phone: user.emergency_contact_phone,
        nationality: user.nationality,
      };
    },
    restoreProfileFromBackup(state) {
      state.user.first_name = state.profileBackup.first_name;
      state.user.last_name = state.profileBackup.last_name;
      state.user.gender = state.profileBackup.gender;
      state.user.birth_date = state.profileBackup.birth_date;
      state.user.mobile_phone = state.profileBackup.mobile_phone;
      state.user.tshirt_size = state.profileBackup.tshirt_size;
      state.user.emergency_contact_name =
        state.profileBackup.emergency_contact_name;
      state.user.emergency_contact_phone =
        state.profileBackup.emergency_contact_phone;
      state.user.nationality = state.profileBackup.nationality;
    },
    updateAddressBackup(state, user) {
      state.addressBackup = {
        address1: user.address1,
        address2: user.address2,
        country_code: user.country_code,
        state: user.state,
        city: user.city,
        zip: user.zip,
      };
    },
    restoreAddressFromBackup(state) {
      state.user.address1 = state.addressBackup.address1;
      state.user.address2 = state.addressBackup.address2;
      state.user.country_code = state.addressBackup.country_code;
      state.user.state = state.addressBackup.state;
      state.user.city = state.addressBackup.city;
      state.user.zip = state.addressBackup.zip;
    },
    updateAccountBackup(state, user) {
      state.accountBackup = { email: user.email };
    },
    restoreAccountFromBackup(state) {
      state.user.email = state.accountBackup.email;
    },
    logOutUser(state) {
      state.isAuthorized = false;
    },
    updatePic(state, photos) {
      if (_.isEmpty(photos)) state.isLoaded.avatarImage = true;
      if (navigator.userAgent.indexOf("Mac OS X") != -1) {
        state.userPic = {
          small: photos.small_retina,
          medium: photos.medium_retina,
          large: photos.large_retina,
        };
      } else {
        state.userPic = {
          small: photos.small,
          medium: photos.medium,
          large: photos.large,
        };
      }
    },
    updateLoadImg(state) {
      state.isLoaded.avatarImage = true;
    },
    updatei18nTranslations(state, lang) {
      state.isLoaded.translations = true;
      state.i18nLoadedPackage = lang;
    },
    updateHeaderLinks(state, links) {
      state.headerLinks = links;
    },
    updateSidebarLinks(state, links) {
      state.sidebarLinks = links;
    },
    changeLoadedStatus(state, { statusName, status }) {
      state.isLoaded[statusName] = status;
    },
    updatei18nLoadedList(state, list) {
      state.i18nLoadedPackageList = list;
    },
    setUserCodes: (state, codes) => {
      state.userCodes.active = codes.active || [];
      state.userCodes.expired = codes.expired || [];
    },
  },
  getters: {
    userData(state) {
      return state.user;
    },
    isAuthorized(state) {
      return state.isAuthorized;
    },
    userPic(state) {
      return state.userPic;
    },
    headerLinks(state) {
      return state.headerLinks;
    },
    sidebarLinks(state) {
      return state.sidebarLinks;
    },
    specificLinks(state) {
      return state.domain;
    },
    // TODO: Delete it
    userLanguage(state) {
      return state.language;
    },
    loadedUserLocale(state) {
      if (
        !(
          state.isLoaded.userLocaleTranslations || state.isLoaded.translations
        ) ||
        !state.user?.language ||
        !state.user?.spartan_country_code
      )
        return false;
      return `${state.user.language}-${state.user.spartan_country_code}`;
    },
    // TODO: Need to rewrite or rename, it is not clear what this getter
    subscriptionStatus(state) {
      let user = state.user;
      if (!user) return false;

      if (user.spartan_plus_member) return "active";

      let stripeSubscription = user.subscription;

      if (stripeSubscription?.status === "unpaid") return "unpaid";

      return false;
    },
    userSubscription(state) {
      let user = state.user;

      if (!user || !user.subscription) return;
      return {
        ...user.subscription,
        isActive: user.subscription.status === "active",
      };
    },
    unassignedTickets(state) {
      return state.user?.unassigned_tickets_events || [];
    },
    isLoadedStatus: (state) => {
      return state.isLoaded;
    },
    // TODO: Union all isLoaded status getters into the One
    isLoadedUserData(state) {
      return state.isLoaded.userData;
    },
    userAvatar(state) {
      return state.isLoaded.avatarImage;
    },
    i18nLoadedList(state) {
      return state.i18nLoadedPackageList;
    },
    userLocaleTranslationsLoaded(state) {
      return state.isLoaded.userLocaleTranslations;
    },
    localeTranslationsLoaded(state) {
      return state.isLoaded.translations;
    },
    i18nTranslations(state) {
      return state.isLoaded.translations;
    },
    activeCodes: (state) => state.userCodes.active,
    expiredCodes: (state) => state.userCodes.expired,
  },
};
