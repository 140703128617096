export const GENDERS = [
  { full: "g_male", short: "M" },
  { full: "g_female", short: "F" },
];
export const T_SHIRT_SIZES = [
  "X-Small",
  "Small",
  "Medium",
  "Large",
  "X-Large",
  "2X-Large",
];

export const TS_CHECKOUT_COUNTRIES = [
  "us",
  "mx",
  "eu",
  "ch",
  "mt",
  "hr",
  "gb",
  "ca",
  "cc",
  "th",
  "si",
  "in",
  "ae",
  "cr",
  "pt",
];
