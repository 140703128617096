<template>
  <div class="sidebar">
    <div class="search-link cursor-pointer" v-if="showSearchLink">
      <a :href="headerConfig.searchLink">{{ $t("search") }}</a>
    </div>
    <div v-if="headerLinks">
      <sidebar-menu />
    </div>

    <a
      v-if="showSpartanPlusLink"
      class="sidebar-item cursor-pointer"
      :href="headerConfig.spartanPlusLink"
    >
      <div class="tree-line">{{ $t("try") }} Spartan EDGE</div>
    </a>

    <div class="profile-line">
      <template v-if="isAuthorized">
        <div class="sidebar-user">
          <user-avatar :size="33" />
          <div v-if="userData" class="user-name">{{ userData.first_name }}</div>
        </div>
        <div class="profile-links">
          <div
            v-if="showHomeLink"
            class="profile-link"
            @click="openView('home')"
          >
            {{ $t("home") }}
          </div>
          <div class="profile-link" @click="openView('events')">
            {{ $t("events") }}
          </div>
          <div class="profile-link" @click="openView('results')">
            {{ $t("results") + " & " + $t("Photos") }}
          </div>
          <div
            v-if="showCodesView"
            class="profile-link"
            @click="openView('my-codes')"
          >
            {{ $t("my_codes") }}
          </div>
          <div class="profile-link" @click="openView('teamsList')">
            {{ $t("teams") }}
          </div>
          <div
            v-if="showTropaionLink"
            class="profile-link"
            @click="openView('tropaion')"
          >
            {{ $t("tropaion") }}
          </div>
          <div class="profile-link" @click="openView('benefits')">
            {{ $t("benefits") }}
          </div>
          <div
            v-if="showVolunteerView"
            class="profile-link"
            @click="openView('volunteers')"
          >
            {{ $t("volunteer") }}
          </div>
          <a class="profile-link" :href="headerConfig.ordersLink">
            {{ $t("orders") }}
          </a>
          <div class="profile-link" @click="openView('settings')">
            {{ $t("settings") }}
          </div>
          <div
            v-if="showSpartanPlusBenefitsLink"
            class="profile-link"
            @click="$router.push({ name: 'SpartanPlusPage' })"
          >
            {{ $t("spartan_plus_perks") }}
          </div>
          <div class="profile-link" @click="userSignOut">
            {{ $t("sign_out") }}
          </div>
        </div>
      </template>
      <div v-else class="auth-links">
        <button
          class="mem-btn btn-secondary-dark sign-up-btn"
          @click="$router.push({ name: 'formSignUp', query: $route.query })"
        >
          {{ $t("join") }}
        </button>
        <button
          class="mem-btn btn-tertiary-dark login-btn"
          @click="$router.push({ name: 'formLogin', query: $route.query })"
        >
          {{ $t("sign_in") }}
        </button>
      </div>
    </div>

    <div class="other-links">
      <div class="other-link" v-if="!isAuthorized">
        <a :href="headerConfig.ordersLink">{{ $t("orders") }}</a>
      </div>
      <div
        class="other-link"
        v-if="userData && userData.spartan_country_code === 'US'"
      >
        <a href="https://deka.fit">Dekafit</a>
      </div>
      <div
        class="other-link"
        v-if="userData && userData.spartan_country_code === 'US'"
      >
        <a href="https://spartanrace.zendesk.com/hc/en-us">{{ $t("help") }}</a>
      </div>
      <div class="regions-btn" @click="openRegionsOverlay">
        <img src="./../assets/locale_icon.svg" alt="flag" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UrlPath } from "@/common/helpers";
import appLinksParams from "@/common/mixins/appLinksParams";
import localizeResolver from "@/common/mixins/localizeResolver";
import SidebarMenu from "@/components/menu/SidebarMenu";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "Sidebar",
  mixins: [localizeResolver, appLinksParams],
  data() {
    return {
      showProfileMenu: false,
    };
  },
  components: {
    UserAvatar,
    SidebarMenu,
  },
  computed: {
    ...mapGetters([
      "userData",
      "isAuthorized",
      "specificLinks",
      "userSubscription",
    ]),
    showHomeLink() {
      return ["US"].includes(this.userCountry);
    },
    showSpartanPlusBenefitsLink() {
      return (
        ["US"].includes(this.userCountry) &&
        this.userSubscription &&
        this.userSubscription.isActive
      );
    },
    showCartLink() {
      return ["US", "MX"].includes(this.userCountry);
    },
    showSearchLink() {
      return ["US"].includes(this.userCountry);
    },
    headerConfig() {
      let actionUrl = this.localizeConfig?.findRaceUrl;
      if (UrlPath.isRelative(this.localizeConfig?.findRaceUrl)) {
        actionUrl = UrlPath.join(
          this.localizeConfig?.findRaceUrl,
          this.localizeConfig?.homeUrl
        );
      }
      let searchLink = this.specificLinks.search;
      let ordersLink = this.specificLinks.orders;
      let spartanPlusUrl = "";
      if (this.userCountry === "US" && this.localizeConfig?.homeUrl) {
        spartanPlusUrl =
          "https://www.spartan.com/pages/spartan-plus-membership";
      }
      return {
        homeUrl:
          this.userCountry === "US"
            ? "https://www.spartan.com/"
            : this.localizeConfig?.homeUrl,
        actionButton: actionUrl,
        spartanPlusLink: spartanPlusUrl,
        searchLink,
        ordersLink,
      };
    },
  },
  methods: {
    openProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu;
    },
    closeSidenav() {
      this.$emit("handle-sidebar");
    },
    openRegionsOverlay() {
      this.$emit("handle-regions-overlay");
    },
    openView(name) {
      this.$router.push({ name }).catch(() => {});
    },
  },
  created() {
    document.getElementsByTagName("html")[0].style.overflowY = "hidden";
  },
  destroyed() {
    document.getElementsByTagName("html")[0].removeAttribute("style");
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  right: 0;
  top: 65px;

  width: 375px;
  background: #121212;
  height: 100%;

  z-index: $zindex-sidebar;

  overflow: scroll;
  padding-bottom: 40px;

  a {
    text-decoration: none;
  }

  .search-link {
    display: flex;
    align-items: center;

    font-size: 16px;
    line-height: 20px;

    letter-spacing: -0.015em;

    background: rgba(255, 255, 255, 0.07);
    padding: 17px 20px;

    a {
      width: 100%;
    }
  }

  .sidebar-item {
    display: flex;
    text-decoration: none;
    padding: 0 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    overflow: hidden;
    transition: all 0.7s ease-in-out;

    .tree-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      line-height: 50px;
      padding: 5px 0;

      position: relative;

      i {
        position: absolute;
        right: 0;
      }
    }

    .tree-column {
      line-height: 50px;
      transition: opacity 0.45s 0.1s;
      overflow: hidden;
      transition: all 0.7s ease-in-out;
    }

    .tree-row {
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;

      .see-all-btn {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.04em;
        text-transform: uppercase;

        margin-bottom: 40px;
        margin-top: 30px;
      }

      .image-line {
        height: 34px;
        margin: 10px 0;
        padding-left: 20px;

        img {
          width: 111px;
          height: 34px;
        }
      }

      .tex-image-line {
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 25px;
        margin-bottom: 10px;
        // margin-bottom: 35px;

        line-height: 0;

        img {
          width: 70px;
          height: 90px;
          margin-right: 20px;
        }

        .line-description {
          max-width: 225px;
          width: 100%;
          height: 100%;

          .description-text {
            font-size: 14px;
            line-height: 21px;

            margin-bottom: 12px;

            cursor: text;
          }

          .description-btn {
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
          }
        }
      }
    }

    .tree-parent {
      padding-left: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .tree-child {
      padding-left: 40px;
      transition: all 0.5s ease-in-out;
    }
  }

  .profile-line {
    padding: 22px 20px 22px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    .sidebar-user {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 12px;
      .user-name {
        padding-left: 15px;
      }
    }

    .profile-links {
      padding-left: 48px;

      .profile-link {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        text-transform: capitalize;
        @include cursorPointer;
      }

      .profile-link:not(:last-child) {
        display: flex;
        padding-bottom: 12px;
      }
    }

    .auth-links {
      .sign-up-btn,
      .login-btn {
        width: 120px;
        height: 46px;
      }
      .sign-up-btn {
        margin-right: 20px;
      }
    }
  }

  .other-links {
    font-size: 16px;
    line-height: 22px;
    padding: 24px 22px;
    height: 250px;

    .other-link {
      margin-bottom: 22px;

      a {
        text-decoration: none;
      }
    }

    .regions-btn {
      height: 45px;
      width: 45px;
      background: #4a4f54;
      border-radius: 50%;
      margin-top: 25px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 23px;
        width: 23px;
      }
    }
  }
}

@media screen and (max-width: 499px) {
  .sidebar {
    background: #121212;
  }
}

@media screen and (max-width: 375px) {
  .sidebar {
    width: 100%;
  }
}
</style>
