import _ from "lodash";
import config from "@/config";
import { regions } from "@/lists";
import { mapGetters, mapActions } from "vuex";

const APP_ENV = process.env.VUE_APP_ENV;
const volunteerCountries = _.flatMap(regions, (region) =>
  _.filter(region.countries, { isVolunteer: true })
);
const allCountries = _.flatMap(regions, (region) =>
  _.map(region.countries, (country) => country)
);
function getCountryLinks(countryCode, languageCode) {
  const country = _.find(allCountries, { code: countryCode });
  return _.find(country?.languages, { code: languageCode });
}

export default {
  computed: {
    ...mapGetters([
      "isAuthorized",
      "userData",
      "subscriptionStatus",
      "headerLinks",
    ]),
    userCountry() {
      return this.userData?.spartan_country_code?.toUpperCase();
    },
    showSpartanPlusLink() {
      const spartanPlusCountries = ["US"];
      let { country } = this.$route.query;

      if (
        !this.isAuthorized &&
        spartanPlusCountries.includes(country?.toUpperCase())
      )
        return true;

      if (
        this.isAuthorized &&
        spartanPlusCountries.includes(this.userCountry) &&
        !this.subscriptionStatus
      )
        return true;

      return false;
    },
    showTropaionLink() {
      return config.showTropaion;
    },
    showVolunteerView() {
      let countries = _.map(volunteerCountries, (vol) => vol.code);
      return countries.includes(this.userCountry);
    },
    showCodesView() {
      const countries = ["US"];
      return countries.includes(this.userCountry);
    },
    volunteerLink() {
      let country = _.find(volunteerCountries, { code: this.userCountry });
      if (!country) return;

      let countryLinks = _.find(country.languages, {
        code: this.userData?.language,
      });
      if (!countryLinks) return;
      return `${countryLinks.homeUrl}/about/volunteers`;
    },
    usLinks() {
      return getCountryLinks("US", "en");
    },
    countryLinks() {
      let country = getCountryLinks(this.userCountry, this.userData?.language);
      if (!country) return this.usLinks;

      return {
        ...country,
        termsUrl: country.termsUrl || this.usLinks.termsUrl,
        privacyPolicyUrl:
          country.privacyPolicyUrl || this.usLinks.privacyPolicyUrl,
      };
    },
  },
  methods: {
    ...mapActions(["logOut"]),
    async userSignOut() {
      await this.logOut();

      let logoutUrl = "https://www.spartan.com/account/logout";

      if (APP_ENV === "local") return this.$router.push({ name: "formLogin" });
      if (APP_ENV === "staging")
        logoutUrl = "https://staging.spartan.com/account/logout";

      window.location = logoutUrl;
    },
  },
  mounted() {},
};
