import { findIndex } from "lodash";

import config from "@/config";

import { KeyConverter } from "@/common/helpers";
import { getCookie } from "@/cookie";

const API = config.apiGate;

export default {
  async createTeam(ctx, payload) {
    const authorization = getCookie("user");
    const response = await fetch(`${API}/account/teams/`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authorization,
      }),
      body: KeyConverter.makeRequestData(payload),
    });
    const responseData = await response.json();
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        data: responseData,
      });
    }
    ctx.commit("setCurrent", responseData);
    return Promise.resolve(responseData);
  },
  async getTeam(ctx, teamId) {
    const authorization = getCookie("user");
    const response = await fetch(`${API}/account/teams/${teamId}`, {
      method: "GET",
      headers: new Headers({
        Authorization: authorization,
      }),
    });
    const responseData = await response.json();
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        data: responseData,
      });
    }
    ctx.commit("setCurrent", responseData);
    console.log("%cTeam data", "color: green", responseData);
    return Promise.resolve(responseData);
  },
  async getTeamByInviteCode(ctx, code) {
    const authorization = getCookie("user");
    const res = await fetch(`${API}/account/teams/${code}/invite_code`, {
      method: "GET",
      headers: new Headers({
        Authorization: authorization,
      }),
    });
    if (!res.ok && res.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!res.ok) return { status: res.status, statusText: res.statusText };
    const resultData = await res.json();
    ctx.commit("setCurrent", resultData);
    console.log("%cTeam data", "color: green", resultData);
  },
  async updateTeam(ctx, { teamId, payload }) {
    const authorization = getCookie("user");
    const response = await fetch(`${API}/account/teams/${teamId}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authorization,
      }),
      body: KeyConverter.makeRequestData(payload),
    });
    const responseData = await response.json();
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        data: responseData,
      });
    }
    ctx.commit("setCurrent", responseData);
    return Promise.resolve(responseData);
  },
  async updateTeamImage(ctx, { teamId, img }) {
    const authorization = getCookie("user");
    const response = await fetch(`${API}/account/teams/${teamId}`, {
      method: "PATCH",
      headers: new Headers({
        Authorization: authorization,
        Accept: "application/json",
      }),
      body: img,
    });
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        data: responseData,
      });
    }
    const responseData = await response.json();
    ctx.commit("setCurrent", responseData);
    console.log("team update result", responseData);
    return Promise.resolve(responseData);
  },
  async deleteTeam(ctx, teamId) {
    const authorization = getCookie("user");
    const response = await fetch(`${API}/account/teams/${teamId}`, {
      method: "DELETE",
      headers: new Headers({
        Authorization: authorization,
      }),
    });
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
      });
    }
    const responseData = await response.json();
    ctx.commit("setCurrent", {});
    console.log("%cTeam data", "color: green", responseData);
    return Promise.resolve(responseData);
  },
  async loadTeamList(ctx, payload = {}) {
    const authorization = getCookie("user");
    const teamUrl = new URL(`${API}/account/teams/`);
    const params = {
      page: payload?.page ?? 1,
      perPage: payload?.perPage ?? 5,
    };
    if (payload.search) {
      params.search = payload.search;
    }
    teamUrl.search = new URLSearchParams(
      KeyConverter.decamelize(params)
    ).toString();
    const response = await fetch(teamUrl.toString(), {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authorization,
      }),
    });
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
      });
    }
    const responseData = await response.json();
    const teams = KeyConverter.camelize(responseData.teams).map((item) => ({
      ...item,
      loading: false,
    }));
    ctx.commit("setList", teams);
    ctx.commit("setPagination", KeyConverter.camelize(responseData.pagination));
    return Promise.resolve(responseData);
  },
  async loadMyTeams(ctx) {
    const authorization = getCookie("user");
    const response = await fetch(`${API}/account/athlete_users/teams`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authorization,
      }),
    });
    const responseData = await response.json();
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
      });
    }
    ctx.commit("setMyTeams", KeyConverter.camelize(responseData.teams));
    return Promise.resolve(responseData);
  },
  async joinTeam(ctx, { teamId, inviteCode }) {
    const authorization = getCookie("user");
    const response = await fetch(`${API}/account/teams/${teamId}/join`, {
      method: "POST",
      headers: new Headers({
        Authorization: authorization,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: inviteCode ? JSON.stringify({ invite_code: inviteCode }) : "",
    });
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject({
        code: response.status,
        message: response.statusText,
      });
    }
    const responseData = await response.json();
    ctx.dispatch("getTeam", teamId);
    return Promise.resolve(responseData);
  },
  async leaveTeam(ctx, teamId) {
    const authorization = getCookie("user");
    if (!teamId) teamId = ctx.state.current.id;
    const response = await fetch(
      `${API}/account/teams/${teamId}/leave`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: authorization,
          Accept: "application/json",
        }),
        // body: JSON.stringify(data),
      }
    );
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
      });
    }
    const responseData = await response.json();
    ctx.dispatch("getTeam", teamId);
    // ctx.commit("updateCurrentTeam", resultData);
    console.log("leave team result", responseData);
    return Promise.resolve(responseData);
  },
  async handleTeamNotifications(ctx, { teamId, data }) {
    const authorization = getCookie("user");
    const response = await fetch(
      `${API}/account/teams/${teamId}/memberships`,
      {
        method: "PATCH",
        headers: new Headers({
          Authorization: authorization,
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(data),
      }
    );
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
      });
    }
    // Perhaps, replace this with mutation
    ctx.dispatch("getTeam", teamId);
    console.log(
      `%cTeam ${teamId} notifications ${data.notifications}`,
      "color: cadetblue"
    );
    // const responseData = await response.json();
    // ctx.commit("setCurrent", responseData);
    // return Promise.resolve(responseData);
  },
  async reportTeam(ctx, { teamId, reason, details }) {
    const authorization = getCookie("user");
    const res = await fetch(`${API}/account/teams/${teamId}/reports`, {
      method: "POST",
      headers: new Headers({
        Authorization: authorization,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ reason, details }),
    });
    if (!res.ok) return { status: res.status, statusText: res.statusText };
    // const resultData = await res.json();
  },
  async getTeamEvents(ctx, teamId) {
    const authorization = getCookie("user");
    if (!teamId) teamId = ctx.state.current.id;
    const resp = await fetch(
      `${API}/account/teams/${teamId}/upcoming_events`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: authorization,
        }),
      }
    );
    if (!resp.ok) return;
    let teamEvents = await resp.json();
    console.log("%cGet team events...", "color: cadetblue", teamEvents.events);
    ctx.commit("updateTeamEventsList", teamEvents.events);
  },
  async getTeamEventMembers(ctx, params) {
    const authorization = getCookie("user");
    let { eventId, teamId } = params;
    if (!teamId) teamId = ctx.state.current.id;
    const resp = await fetch(
      `${API}/account/teams/${teamId}/upcoming_events/${eventId}/schedule`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: authorization,
        }),
      }
    );
    if (!resp.ok) return;
    let scheduleList = await resp.json();
    console.log(
      "%cGet team events members...",
      "color: cadetblue",
      scheduleList.members
    );
    ctx.commit("updateTeamEventMembers", scheduleList.members);
  },
  async getTeamMembers(ctx, { teamId, queryType, queryLimit, queryOffset }) {
    // Query Types: 'default', 'loadMore', 'loadHidden', 'search'

    if (!teamId) teamId = ctx.state.current.id;
    if (!queryType) queryType = "default";
    if (queryType === "default") ctx.commit("setLeaderboardOffset", 1);

    let { offset, limit, category, year, sort, direction, search } =
      ctx.state.leaderboardListParams;

    if (queryLimit) limit = queryLimit;
    if (queryOffset) offset = queryOffset;

    const authorization = getCookie("user");
    const response = await fetch(
      `${API}/account/teams/${teamId}/memberships?limit=${limit}&year=${year}&category=${category}&sort=${sort}&direction=${direction}&search=${search}${
        offset !== 1 ? "&offset=" + offset : ""
      }`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: authorization,
        }),
      }
    );
    if (!response.ok)
      return { status: response.status, statusText: response.statusText };
    let members = await response.json();
    if (queryType === "default") {
      // remove last 4 membest in top list if it not contain current user
      let currentUserId = ctx.rootState.profile.user.id;
      let currentUserIndex = findIndex(members.members, {
        id: currentUserId,
      });
      let currentUserBottomIndex = findIndex(members.current_members, {
        id: currentUserId,
      });

      // this is bad
      if (
        currentUserIndex < 0 &&
        currentUserBottomIndex >= 0 &&
        members.members.length === 15
      ) {
        if (members.current_members.length <= 2) members.members.splice(-3, 3);
        else members.members.splice(-4, 4);
      }
      //
      ctx.commit("updateTeamMembersList", members.members);
      ctx.commit("updateTeamMembersBottomList", members.current_members);
    }
    if (queryType === "loadHidden")
      ctx.commit("updateTeamMembersList", [
        ...ctx.state.teamMembers,
        ...members.members,
      ]);
    if (queryType === "loadMore")
      ctx.commit("updateTeamMembersBottomList", [
        ...ctx.state.teamMembersBottom,
        ...members.members,
      ]);

    if (queryType === "search") {
      ctx.commit("updateTeamMembersList", [
        ...ctx.state.teamMembers,
        ...members.members,
      ]);
      ctx.commit("updateTeamMembersBottomList", []);
    }
    ctx.commit("setLeaderboardOffset", ctx.state.teamMembers.length + 1);
    ctx.commit("setLeaderboardTotalCount", members.total);

    console.log("%cGet team members...", "color: cadetblue", members);
    console.log(ctx.state.leaderboardListParams, "list params");
  },
  async getLeaderboardFilters(ctx, teamId) {
    const authorization = getCookie("user");
    const response = await fetch(
      `${API}/account/teams/${teamId}/memberships/filters`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: authorization,
        }),
      }
    );
    if (!response.ok) return;
    const filters = await response.json();
    ctx.commit("setLeaderboardTableFilters", filters);
    console.log(filters, "list filters");
  },
  applyLeaderboardFilters(ctx, filters) {
    ctx.commit("setLeaderboardFilters", filters);
    // ctx.dispatch("getTeamMembers", {});
  },
  changeLeaderboardSort(ctx, newSortParam) {
    let { sort, direction } = ctx.state.leaderboardListParams;
    let newSortDirection;

    if (sort !== newSortParam) newSortDirection = "desc";
    else newSortDirection = direction === "asc" ? "desc" : "asc";

    // if (sort !== newSortParam) newSortDirection = "desc";
    // else newSortDirection = direction;

    // if (!newSortDirection) newSortDirection = "asc";
    // else if (newSortDirection === "asc") newSortDirection = "desc";
    // else {
    //   newSortParam = "";
    //   newSortDirection = "";
    // }

    ctx.commit("setLeaderboardSortParam", {
      sort: newSortParam,
      direction: newSortDirection,
    });
    ctx.dispatch("getTeamMembers", {});
  },
  updateLeaderboardSort(ctx, params) {
    ctx.commit("setLeaderboardSortParam", params);
    // ctx.dispatch("getTeamMembers", {});
  },
  changeLeaderboardSearch(ctx, searchText) {
    ctx.commit("setLeaderboardSearch", searchText);
  },
  clearLeaderboardList(ctx) {
    ctx.commit("updateTeamMembersList", []);
    ctx.commit("setLeaderboardOffset", 1);
    ctx.commit("updateTeamMembersList", []);
    ctx.commit("updateTeamMembersBottomList", []);
    ctx.commit("setLeaderboardSortParam", {
      sort: "events",
      direction: "desc",
    });
    ctx.commit("setLeaderboardFilters", { category: "", year: "" });
  },
  async changeTeamMemberRole(ctx, { memberId, role }) {
    const authorization = getCookie("user");
    const teamId = ctx.state.current.id;
    const res = await fetch(
      `${API}/account/teams/${teamId}/memberships/${memberId}`,
      {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
        body: JSON.stringify({ role }),
      }
    );
    if (!res.ok) return;
    let result = await res.json();
    console.log(result, "update role result");

    // update member role

    let topListIndex = findIndex(ctx.state.teamMembers, { id: memberId });
    let bottomListIndex = findIndex(ctx.state.teamMembersBottom, {
      id: memberId,
    });
    if (topListIndex >= 0)
      ctx.commit("updateMemberRole", {
        index: topListIndex,
        field: "role",
        value: role,
        list: "top",
      });
    if (bottomListIndex >= 0)
      ctx.commit("updateMemberRole", {
        index: bottomListIndex,
        field: "role",
        value: role,
        list: "bottom",
      });

    // pass team ownership
    if (role === "owner") {
      let ownerId = ctx.rootState.profile.user.id;
      let ownerTopListIndex = findIndex(ctx.state.teamMembers, { id: ownerId });
      let ownerBottomListIndex = findIndex(ctx.state.teamMembersBottom, {
        id: ownerId,
      });
      if (ownerTopListIndex >= 0)
        ctx.commit("updateMemberRole", {
          index: ownerTopListIndex,
          field: "role",
          value: "captain",
          list: "top",
        });
      if (ownerBottomListIndex)
        ctx.commit("updateMemberRole", {
          index: ownerBottomListIndex,
          field: "role",
          value: "captain",
          list: "bottom",
        });
    }

    ctx.dispatch("getTeam", teamId);

    console.log(ctx.state.teamMembers, "top list");
    console.log(ctx.state.teamMembersBottom, "bottom list");
  },
  async removeTeamMember(ctx, memberId) {
    const authorization = getCookie("user");
    const teamId = ctx.state.current.id;
    const res = await fetch(
      `${API}/account/teams/${teamId}/memberships/${memberId}`,
      {
        method: "DELETE",
        headers: new Headers({
          Authorization: authorization,
        }),
      }
    );
    if (!res.ok) return;

    // let result = await res.json();
    // console.log(result, "remove member result");

    // remove member from list
    let topListIndex = findIndex(ctx.state.teamMembers, { id: memberId });
    let bottomListIndex = findIndex(ctx.state.teamMembersBottom, {
      id: memberId,
    });

    if (topListIndex >= 0)
      ctx.commit("removeMemberFromList", { index: topListIndex, list: "top" });
    if (bottomListIndex >= 0)
      ctx.commit("removeMemberFromList", {
        index: bottomListIndex,
        list: "bottom",
      });
  },
};
