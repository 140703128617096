import itemImage1 from "@/assets/benefit_card_image_1.png";
// import itemImage2 from "@/assets/benefit_card_image_2.png";
import itemImage3 from "@/assets/benefit_card_image_3.png";
import itemImage4 from "@/assets/benefit_card_image_4.png";
import itemImage5 from "@/assets/benefit_card_image_5.png";
// import itemImage6 from "@/assets/benefit_card_image_6.png";
// import itemImage7 from "@/assets/benefit_card_image_7.png";
import itemImage8 from "@/assets/benefit_card_image_8.png";
import itemImage9 from "@/assets/benefit_card_image_9.png";
// import itemImage10 from "@/assets/benefit_card_image_10.png";

export const MEMBER_BENEFITS_LIST = [
  {
    id: 0,
    image: itemImage1,
    title: "Obstacle Intensives",
    description:
      "Access exclusive training programs tailored to your race, obstacle tips, nutrition & lifestyle content for peak performance. Access via The Spartan App.",
  },
  // {
  //   id: 1,
  //   image: itemImage2,
  //   title: "Member Zone",
  //   description:
  //     "Bag check, private bathrooms, changing tents, hot coffee, snacks, and seating. Spartan isn’t known for comfort but the member zone provides the best experience on race-day.",
  // },
  {
    id: 2,
    image: itemImage3,
    title: "Guaranteed Start Time",
    description:
      "Your start time is guaranteed when you purchase an open category ticket.",
  },
  {
    id: 3,
    image: itemImage4,
    title: "Save 20% on Gear",
    description:
      "Get our best prices on top-selling gear & training equipment.",
  },
  {
    id: 4,
    image: itemImage5,
    title: "Free Shipping & Returns",
    description:
      "Save $$$ and gain peace of mind. Members get free standard shipping and returns for online orders (US ONLY).",
  },
  // {
  //   id: 5,
  //   image: itemImage6,
  //   title: "100% Ticket Protection",
  //   description:
  //     "Commit to events with confidence. If your event gets canceled or moved by Spartan, members may request a full refund of tickets, add ons, and fees.",
  // },
  // {
  //   id: 6,
  //   image: itemImage7,
  //   title: "HD Photo Credit",
  //   description:
  //     "Our on-course photos are getting an upgrade this year and Spartan+ members have the best deal. You worked hard to get here so make sure to save the memory.",
  // },
  {
    id: 7,
    image: itemImage8,
    title: "Member-Only Deals",
    description:
      "Spartan EDGE members gain access to additional discounts, including some of your favorite races & merch.",
  },
  {
    id: 8,
    image: itemImage9,
    title: "Community Access",
    description:
      "Connect with the OCR community like never before. Access community posts & in-app messaging.",
  },
  //   {
  //     id: 9,
  //     image: itemImage10,
  //     title: "Recovery Services",
  //     description: "S+ members gain access to expert trainer & recovery specialist Rocco Castelano (@trainwithrocco). Recovery services such as massage, compression, and ice baths.",
  //   },
];
