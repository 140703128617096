export default {
  showTropaion: true, // "tropaion" route access
  apiGate: "https://api-gate-staging.eks.spartan.com",
  apiAuth: "https://api-gate-staging.eks.spartan.com/auth",
  apiAccount: "https://api-gate-staging.eks.spartan.com/account",
  apiSportograf: "https://sportograf-staging.eks.spartan.com",
  socketConnectionUrl: "wss://delphi-socket-staging.eks.spartan.com/cable",
  api: "https://account-staging.spartan.com",
  homePage: "http://localhost:8080/login",
  searchUrl: "https://staging.spartan.com/search",
  shopUrl: "https://staging.spartan.com/cart",
  findRaceUrl: "https://race.staging.spartan.com/en/race/find-race",
  authPage: "https://contentful-pet-staging.eks.spartan.com/en",
  ordersUrl: "https://staging.spartan.com/account",
  headerLinksApi: "https://race.staging.spartan.com/api/content/unified_nav",
  stripe: {
    key: "pk_test_zk1D9hwJEa3XSV6wCsIXyiMf",
    keys: {
      us: "pk_test_zk1D9hwJEa3XSV6wCsIXyiMf",
      eu: "pk_test_zk1D9hwJEa3XSV6wCsIXyiMf",
      gb: "pk_test_zk1D9hwJEa3XSV6wCsIXyiMf",
    },
    yearly: {
      productId: "prod_I1RP7KVQpp7r22",
      priceId: "price_1HSmChEClUOaV6ugpWp3CCsm",
    },
    monthly: {
      productId: "prod_J0M31jhCpJMR1k",
      priceId: "price_1IOLLhEClUOaV6ugu0bxZiYY",
    },
  },
  checkout: {
    us: {
      spartanFoundationQuestionId: "12",
      // spartanPlusTicket: {
      //   ticketTypeId: "6988",
      //   eventId: "176",
      // },
      refundProtectVendorCode: "ven_local_fc75cfeb1a31402f8c75c9822b44e51c",
    },
    ca: {
      refundProtectVendorCode: "ven_local_fc75cfeb1a31402f8c75c9822b44e51c",
    },
    eu: {
      spartanFoundationQuestionId: "35",
      refundProtectVendorCode: "ven_local_fc75cfeb1a31402f8c75c9822b44e51c",
    },
    gb: {
      refundProtectVendorCode: "ven_local_fc75cfeb1a31402f8c75c9822b44e51c",
    },
  },
  sezzlePublicKey: "sz_pub_xYZ1lNqGPAwcuifLKmNcf5pIKhzau6wo",
  sezzleConfig: {
    mode: "iframe",
    publicKey: "sz_pub_xYZ1lNqGPAwcuifLKmNcf5pIKhzau6wo",
    apiMode: "sandbox",
    isVirtualCard: true,
  },
  contentful: {
    spaceID: "1bk7n9wtl2gh",
    sysId: "3TTtSH50Hn3GE2m4Apul19",
    environment: "nuxt-dev",
    serviceToken:
      "f3426e9333619474060f9862eb4271f221641bfbf08cb4be103e0cd8dbbf7ff2",
    entries: {
      passes: "2rdzcVoehfxjkHVCKMIhj8",
    },
  },
};
