import { getCookie } from "@/cookie.js";
import config from "@/config";
import _ from "lodash";
import Vue from "vue";

const API = config.apiAccount;
const API_SPORTOGRAF = config.apiSportograf;
let API_MEMBERSHIP = "";

if (process.env.VUE_APP_ENV === "local")
  API_MEMBERSHIP = "https://account-staging.spartan.com";

export default {
  state: {
    result: {},
    resultsList: [],
    resultsTotal: {},
    pagination: {
      offset: 0,
      limit: 15,
      total: 0,
    },
    sort: {
      sortBy: "",
      sortDirection: "",
    },
    obstacles: [],
    //
    resultLeaderboard: {
      listTop: [],
      listBottom: [],
      total: 0,
      offset: 1,
      limit: 15,
    },
  },
  actions: {
    async getUserResults(ctx) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/race_results`, {
        method: "GET",
        headers: new Headers({
          Authorization: authorization,
        }),
      });
      if (!res.ok && res.status === 500)
        ctx.dispatch("handleToolbarNotification");
      const racesData = await res.json();
      ctx.commit("updateUserResults", racesData);
    },
    async loadMoreRaces(ctx, newOffset) {
      ctx.commit("changePagination", newOffset);
    },
    // need to optimise this fn for filters
    addSortParam(ctx, sortParams) {
      let { sortBy } = this.state.results.sort;

      if (sortBy !== sortParams.sortBy) sortParams.sortDirection = "";

      if (!sortParams.sortDirection) sortParams.sortDirection = "asc";
      else if (sortParams.sortDirection === "asc")
        sortParams.sortDirection = "desc";
      else {
        sortParams.sortBy = "";
        sortParams.sortDirection = "";
      }

      ctx.commit("updateSort", sortParams);
      ctx.commit("changePagination", 0);
    },
    updateSortParams(ctx, sortParams) {
      ctx.commit("updateSort", sortParams);
    },
    clearFilters(ctx) {
      ctx.commit("clearAllFilters");
    },
    async getResult(ctx, resultId) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/race_results/${resultId}`, {
        method: "GET",
        headers: new Headers({
          Authorization: authorization,
        }),
      });
      if (!res.ok && res.status === 500)
        ctx.dispatch("handleToolbarNotification");
      if (!res.ok)
        return Promise.reject(`Get result error. Status: ${res.status}`);
      const resultData = await res.json();

      ctx.commit("updateResultData", resultData);
      return ctx.getters.resultData;
    },
    async getResultPhotos(ctx, resultId) {
      const authorization = getCookie("user");
      const res = await fetch(
        `${API_SPORTOGRAF}/account/race_results/${resultId}`,
        {
          method: "PUT",
          headers: new Headers({
            Authorization: authorization,
            Accept: "application/json",
          }),
          body: null,
        }
      );

      if (!res.ok && res.status === 500)
        ctx.dispatch("handleToolbarNotification");
      if (!res.ok) {
        let uploadError = await res.json();
        return Promise.reject(uploadError?.error);
      }

      const result = await res.json();
      ctx.commit("updateResultPhotosStatuses", result.data);
      ctx.commit("setResultPhotos", {
        photos: result.data?.media || [],
        type: "userPhotos",
      });
      // bonus_media
      ctx.commit("setResultPhotos", {
        photos: result.data?.bonus_media || [],
        type: "bonusPhotos",
      });
    },
    async submitResultFeedback(ctx, { resultId, data }) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/race_results/${resultId}/feedbacks`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
        body: JSON.stringify(data),
      });
      if (!res.ok)
        return Promise.reject(`Feedback submit error. Status: ${res.status}`);
      const result = await res.json();
      ctx.commit("updateResultFeedback", result);
    },
    async downloadPhoto(ctx, params) {
      const authorization = getCookie("user");
      const res = await fetch(params.url, {
        headers: new Headers({
          Authorization: authorization,
        }),
      });
      return res;
    },
    async getPhotoPreview(ctx, params) {
      const authorization = getCookie("user");
      const res = await fetch(
        `${API_MEMBERSHIP}/api/v1/race_results/${params.resultId}/share/preview`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: authorization,
          }),
          body: params.img,
        }
      );
      const resultData = await res.blob();
      // console.log(resultData);
      return resultData;
    },
    async getPhotoShareLink(ctx, params) {
      const authorization = getCookie("user");
      const res = await fetch(
        `${API_MEMBERSHIP}/api/v1/race_results/${params.resultId}/share`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: authorization,
          }),
          body: params.img,
        }
      );
      const resultData = await res.json();
      // console.log(resultData, "res data");
      return resultData;
    },
    async getObstaclesList(ctx, eventId) {
      // console.log("%cGet obs list...", "color: green", eventId);
      // ctx.commit("updateObstaclesList", ctx.state.testObstacles);
      const authorization = getCookie("user");
      const res = await fetch(`${API}/events/${eventId}/obstacles`, {
        method: "GET",
        headers: new Headers({
          Authorization: authorization,
        }),
      });
      if (!res.ok && res.status === 500)
        ctx.dispatch("handleToolbarNotification");
      const result = await res.json();
      // console.log(result.obstacles, "obstacles");
      if (result.obstacles) ctx.commit("updateObstaclesList", result.obstacles);
    },
    async reportObstacle(ctx, params) {
      ctx.commit("updateObstacleStasus", params);
      let payload = {
        is_passed: params.isPassed,
      };
      const authorization = getCookie("user");
      const res = await fetch(
        `${API}/events/${params.eventId}/obstacles/${params.obstacle.obstacle_event_id}`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: authorization,
            "Content-Type": "application/json",
            Accept: "application/json",
          }),
          body: JSON.stringify(payload),
        }
      );
      const result = await res.json();
      if (result.obstacles) ctx.commit("updateObstaclesList", result.obstacles);
    },
    async getResultLeaderboard(ctx, params) {
      let { offset, limit } = ctx.state.resultLeaderboard;
      let { resultId, queryType, queryLimit, queryOffset } = params;

      if (!resultId) resultId = ctx.state.result.result.result_id;
      if (queryLimit) limit = queryLimit;
      if (queryOffset) offset = queryOffset;

      const authorization = getCookie("user");
      const res = await fetch(
        `${API}/race_results/${resultId}/leaderboard?direction=asc&limit=${limit}${
          offset === 1 ? "" : "&offset=" + offset
        }`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: authorization,
          }),
        }
      );

      if (!res.ok)
        return Promise.reject(
          `Get result leaderboard error. Status: ${res.status}`
        );

      const resultLeaderboard = await res.json();

      if (!queryType) {
        // remove last 4 membest in top list if it not contain current user
        let currentUserId = ctx.rootState.profile.user.id;
        let currentUserIndex = _.findIndex(resultLeaderboard.members, {
          id: currentUserId,
        });
        let currentUserBottomIndex = _.findIndex(
          resultLeaderboard.current_members,
          {
            id: currentUserId,
          }
        );

        // this is bad
        if (
          currentUserIndex < 0 &&
          currentUserBottomIndex >= 0 &&
          resultLeaderboard.members.length === 15
        ) {
          if (resultLeaderboard.current_members.length <= 2)
            resultLeaderboard.members.splice(-3, 3);
          else resultLeaderboard.members.splice(-4, 4);
        }
        //

        ctx.commit("updateResultLeaderboard", {
          key: "listTop",
          value: resultLeaderboard.members,
        });
        ctx.commit("updateResultLeaderboard", {
          key: "listBottom",
          value: resultLeaderboard.current_members,
        });
      }
      if (queryType === "loadHidden") {
        ctx.commit("updateResultLeaderboard", {
          key: "listTop",
          value: [
            ...ctx.state.resultLeaderboard.listTop,
            ...resultLeaderboard.members,
          ],
        });
      }
      if (queryType === "loadMore") {
        ctx.commit("updateResultLeaderboard", {
          key: "listBottom",
          value: [
            ...ctx.state.resultLeaderboard.listBottom,
            ...resultLeaderboard.members,
          ],
        });
      }

      ctx.commit("updateResultLeaderboard", {
        key: "offset",
        value: ctx.state.resultLeaderboard.listTop.length + 1,
      });
      ctx.commit("updateResultLeaderboard", {
        key: "total",
        value: resultLeaderboard.total,
      });
    },
    clearResultLeaderboard(ctx) {
      ctx.commit("updateResultLeaderboard", { key: "listTop", value: [] });
      ctx.commit("updateResultLeaderboard", { key: "listBottom", value: [] });
      ctx.commit("updateResultLeaderboard", { key: "offset", value: 1 });
      ctx.commit("updateResultLeaderboard", { key: "total", value: 0 });
    },
    async getResultShareImage(ctx, { resultId, orientation }) {
      let res = await fetch(
        `${API}/race_results/${resultId}/share?orientation=${orientation}`
      );
      let blob = await res.blob();

      return {
        file: new File([blob], `result_${resultId}.png`, {
          type: blob.type,
        }),
        src: URL.createObjectURL(blob),
      };
    },
    // Sportograf API
    async uploadSelfie(ctx, params) {
      const authorization = getCookie("user");
      let { resultId, image } = params;
      const res = await fetch(
        `${API_SPORTOGRAF}/account/race_results/${resultId}`,
        {
          method: "PUT",
          headers: new Headers({
            Authorization: authorization,
            // "Content-Type": "application/json",
            Accept: "application/json",
          }),
          body: image,
        }
      );
      if (!res.ok && res.status === 500)
        ctx.dispatch("handleToolbarNotification");
      if (!res.ok) {
        let uploadError = await res.json();
        return Promise.reject(uploadError?.error);
      }
      const result = await res.json();
      ctx.commit("updateResultPhotosStatuses", result.data);
      ctx.commit("setResultPhotos", {
        photos: result.data?.media || [],
        type: "userPhotos",
      });
      // bonus_media
      ctx.commit("setResultPhotos", {
        photos: result.data?.bonus_media || [],
        type: "bonusPhotos",
      });
      // Check statuses
      return result.data;
    },
    async getPhotosCartUrl(ctx, resultId) {
      const authorization = getCookie("user");
      const res = await fetch(
        `${API_SPORTOGRAF}/account/race_results/${resultId}/cart_link`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: authorization,
            Accept: "application/json",
          }),
          // body: image,
        }
      );
      if (!res.ok) {
        let uploadError = await res.json();
        return Promise.reject(uploadError?.error);
      }
      const result = await res.json();
      return result?.cart_link;
    },
  },
  mutations: {
    updateResultData(state, result) {
      state.result = result;
    },
    updateResultPhotosStatuses(state, data) {
      if (!data) return;
      // console.log("Sportograf response", data);
      Vue.set(
        state.result.result,
        "sportograf_purchase_status",
        data.purchase_status
      );
      Vue.set(
        state.result.result,
        "sportograf_selfie_uploaded",
        data.sportograf_selfie_uploaded
      );
      Vue.set(state.result.result, "sportograf_status", data.sportograf_status);
      Vue.set(state.result.result, "sportograf_login_link", data.login_link);
      Vue.set(state.result.result, "not_found_reason", data.not_found_reason);
    },
    setResultPhotos(state, { photos, type }) {
      let list = _.map(photos, (media) => {
        return _.reduce(
          media.variants,
          (acc, { url, variant }) => ({ ...acc, [variant]: url }),
          {}
        );
      });
      if (type === "userPhotos") Vue.set(state.result, "photos", list);
      if (type === "bonusPhotos") Vue.set(state.result, "bonusPhotos", list);
    },
    updateResultFeedback(state, feedback) {
      Vue.set(state.result, "feedback", feedback);
    },
    updateResultPhotos(state, data) {
      Vue.set(state.result, "photobutler_attempt", data.attempt);

      if (_.isEmpty(data.athlete_images)) return;

      if (!state.result.photos)
        Vue.set(state.result, "photos", [...data.athlete_images]);
      else {
        let images = _.map(data.athlete_images, (image) => {
          image.athlete_image_id = image.id;
          return image;
        });
        Vue.set(
          state.result,
          "photos",
          _.uniqBy([...state.result.photos, ...images], "athlete_image_id")
        );
      }
    },
    updateUserResults(state, { results, totals }) {
      state.resultsList = results || [];
      state.resultsTotal = totals;
      state.pagination.total = results.length;
    },
    changePagination(state, offset) {
      state.pagination.offset = offset;
    },
    updateSort(state, sort) {
      state.sort = sort;
    },
    clearAllFilters(state) {
      state.sort = {
        sortBy: "",
        sortDirection: "",
      };
    },
    updateObstaclesList(state, list) {
      state.obstacles = list;
    },
    updateObstacleStasus(state, params) {
      let obsIndex = _.findIndex(state.obstacles, {
        obstacle_event_id: params.obstacle.obstacle_event_id,
      });
      state.obstacles[obsIndex].is_passed = params.isPassed;
    },
    updateResultLeaderboard(state, { key, value }) {
      state.resultLeaderboard[key] = value;
    },
    // result photo download
    updatePhotosListItem(state, { photoId, payload }) {
      // console.log(photoId, payload, "MUT");
      let index = _.findIndex(state.result.photos, {
        athlete_image_id: photoId,
      });
      // console.log(index, "INDx");

      Vue.set(state.result.photos, index, {
        ...state.result.photos[index],
        ...payload,
      });

      // console.log(state.result.photos[index], "PHOTO OBJ");
    },
  },
  getters: {
    resultData(state) {
      return { ...state.result, ...state.result.result };
    },
    userResults(state) {
      return state.resultsList;
    },
    userStats(state) {
      return state.resultsTotal;
    },
    pagination(state) {
      return state.pagination;
    },
    sort(state) {
      return state.sort;
    },
    unreportedObstaclesList(state) {
      return _.filter(state.obstacles, { is_passed: null });
    },
    reportedObstacles(state) {
      return state.obstacles;
    },
    resultLeaderboard(state) {
      return state.resultLeaderboard;
    },
  },
};
