import Vue from "vue";
import queryString from "query-string";
import {
  map,
  find,
  groupBy,
  each,
  times,
  head,
  filter,
  orderBy,
  toNumber,
  uniq,
  uniqBy,
  includes,
  some,
  toUpper,
  keyBy,
  merge,
  isEmpty,
} from "lodash";

import config from "@/config";
import { getCookie } from "@/cookie";

const API = config.apiGate;
const ERROR_CODES = [422, 500];

async function handleError(response, source) {
  // source - Shows what API request fails

  // special errors
  // postOrder
  if (source === "post-order-non-retryable") {
    return Promise.reject({
      title: "checkout_create_order_error_title",
      message: "checkout_create_order_error_message",
      source,
    });
  }
  if (source === "post-order-missing-id") {
    return Promise.reject({
      title: "checkout_order_id_error_title",
      message: "checkout_order_id_error_message",
      source,
    });
  }
  if (source === "payu-transaction")
    return Promise.reject({ title: response.title });

  if (response?.status === 666)
    return Promise.reject({ title: "Your cart is empty" });

  if (!ERROR_CODES.includes(response.status))
    return Promise.reject({ title: "Unexpected error" });

  let error = await response.json();

  return Promise.reject({
    title: error?.errors?.header,
    message: error?.errors?.text,
    source,
  });
}
function runWaitTimer(ctx) {
  let timeoutID = setTimeout(() => {
    ctx.dispatch("handleToolbarNotification", {
      type: "warning",
      text: "checkout_warning_server_sleeping",
      isVisible: true,
      canClose: true,
    });
  }, 15000);
  return timeoutID;
}

// dev
async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  state: {
    checkoutRegion: "us",
    params: {},

    list: [],
    orderDetails: {}, // Contains all information about price to display
    order: {}, // Contains information, which one need for stripe and TS

    paymentMethods: [],
    promoCode: {
      isValid: false,
      value: "",
    },
    billingAddress: {
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    completeOrderResponse: {},
    // US checkout
    spartanPlusTicket: false,

    spartanFoundationQuestion: {},
    spartanFoundationQuestionAnswer: {},
    checkoutFundraisingCompany: {},

    refoundProtectData: {},
  },
  actions: {
    async getOrderDetails(ctx) {
      localStorage.removeItem("checkoutCoupon");
      let timeoutID = runWaitTimer(ctx);
      let {
        checkoutRegion,
        storedTickets,
        cartDetails,
        userData,
        spartanFoundationQuestionId,
      } = ctx.getters;
      const REGION = checkoutRegion.toLowerCase();
      const SPARTAN_PLUS_TICKET = config.checkout[REGION]?.spartanPlusTicket;

      // Get tickets from cart
      let ticketTypeIds = storedTickets.map(
        (ticket) => ticket.ts_ticket_type_id
      );

      // Cart is empty
      if (ticketTypeIds && ticketTypeIds.length === 0) {
        clearTimeout(timeoutID);
        return handleError({ status: 666 });
      }

      const authorization = getCookie("user");
      let payload = {
        ticket_type_ids: ticketTypeIds,
      };
      const res = await fetch(
        `${API}/account/checkout/orders?country_code=${checkoutRegion}&${queryString.stringify(
          payload,
          {
            arrayFormat: "bracket",
          }
        )}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
        }
      );
      clearTimeout(timeoutID);
      ctx.dispatch("handleToolbarNotification", {
        isVisible: false,
      });
      // errors
      if (!res.ok) return await handleError(res, "get-order");

      let result = await res.json();
      // Parse TS response
      try {
        // uniq eventIds list (used in addons logic)
        let eventIds = uniq(
          map(result.ticket_types, (ticket) => {
            return ticket.event.id;
          })
        );
        const purchaserQuestions = [];
        const spartanFoundationQuestions = [];

        let ticketsList = map(result.ticket_types, (ticketType) => {
          let ticketTypeId = ticketType.ticket_type_id;
          // find ticket in cart
          let cartTicket = find(storedTickets, {
            ts_ticket_type_id: toNumber(ticketTypeId),
          });
          // find current ticketType
          let ticket = find(ticketType.ticket_types, {
            id: ticketTypeId,
          });
          // transform ticket tags
          ticket.tags = map(ticket.tags, (tag) => toUpper(tag));
          // mark Spartan plus ticket
          if (
            SPARTAN_PLUS_TICKET &&
            SPARTAN_PLUS_TICKET.ticketTypeId === ticket.id
          ) {
            ctx.commit("setSpartanPlusTicket", true);
            return { spartanPlusTicket: true, ...ticket };
          }

          let { event, waiver, attendee_questions, purchaser_questions } =
            ticketType;

          // Transform event.refund_protect_disable: ["0", "1"] field value to correct type
          event.refund_protect_disable =
            event.refund_protect_disable === "1" ? true : false;

          // parsed Attendee questions
          let ticketAttendeeQuestions = map(
            attendee_questions?.attendeeQuestions,
            (question) => {
              // modify tags array
              let tags = map(question.tags, (tag) => toUpper(tag));

              // Add additional fields for questions:
              // SpartanTab
              // Photo package
              // T-Shirt
              if (tags.includes("ADDON")) {
                // get answer with price
                let trueAnswer = find(
                  question.questionAnswers,
                  (answer) => toNumber(answer.addOnAmount) > 0
                );
                // get answer from cart
                let cartAnswer = find(
                  cartTicket.addonQuestions,
                  (cartQuestion) =>
                    cartQuestion.questionId === question.questionId
                );

                Object.assign(question, {
                  selectedQuantity: cartAnswer
                    ? cartAnswer.selectedQuantity
                    : 0,
                  memAddonType: "question",
                  complexQuestion: false,
                  memAnswer: trueAnswer.answer,
                  memPrice: toNumber(trueAnswer.addOnAmount),
                });
              }
              // Questions with multiple answers
              if (tags.includes("SIZE")) {
                let baseAnswer = find(
                  question.questionAnswers,
                  (answer) => toNumber(answer.addOnAmount) > 0
                );
                // get answer from cart
                let cartQuestion = find(
                  cartTicket.addonComplexQuestions,
                  (addonQuestion) =>
                    addonQuestion.questionId === question.questionId
                );

                Object.assign(question, {
                  selectedQuantity: cartQuestion?.selectedQuantity || 0,
                  memAddonType: "complexQuestion",
                  complexQuestion: true,
                  memAnswers: cartQuestion?.memAnswers || [],
                  memPrice: toNumber(baseAnswer.addOnAmount),
                  questionAnswers: map(question.questionAnswers, (answer) => {
                    if (cartQuestion) {
                      let cartQuestionAnswer = find(
                        cartQuestion.questionAnswers,
                        { id: answer.id }
                      );
                      return {
                        ...answer,
                        selectedQuantity: cartQuestionAnswer.selectedQuantity,
                      };
                    }

                    return { selectedQuantity: 0, ...answer };
                  }),
                });
              }

              return { ...question, tags };
            }
          );

          // filter: get list of questions with tag "elite"
          // map: modify list, add fields for v-model etc.
          // orderBy: order questions list by "ordering" field
          let customerQuestions = orderBy(
            map(
              filter(ticketAttendeeQuestions, (question) =>
                question.tags.includes("ELITE")
              ),
              (question) => {
                let { questionType } = question;
                let componentName;

                if (questionType === "text")
                  componentName = "CustomerQuestionTypeText";
                if (questionType === "checkbox")
                  componentName = "CustomerQuestionTypeCheckbox";
                if (questionType === "multiple")
                  componentName = "CustomerQuestionTypeMultiple";

                return {
                  ...question,
                  //
                  componentName,
                  answerText: null,
                  answerId: null,
                };
              }
            ),
            ["ordering"]
          );
          // fill customerQuestions with saved data from cart
          if (cartTicket?.customerQuestions?.length !== 0) {
            let lookup = keyBy(cartTicket.customerQuestions, "questionId");
            customerQuestions = map(customerQuestions, (q) =>
              merge(q, lookup[q.questionId])
            );
          }
          // Checkout US: Attendee questions with tag FEE
          let taxesQuestions = map(
            filter(ticketAttendeeQuestions, (question) =>
              question.tags.includes("FEE")
            ),
            (question) => {
              return {
                id: question.questionId,
                answer: question.questionAnswers[0]?.answer,
              };
            }
          );

          // filter: get questions with questionType "text"
          // map: transform purchaserQuestions object into array
          let ticketPurchaserQuestions = filter(
            map(purchaser_questions?.purchaserQuestions, (question) => {
              let savedQuestion = find(cartTicket.purchaserQuestions, {
                questionId: question.questionId,
              });
              return {
                ...question,
                answerModel: savedQuestion?.answerModel || "",
              };
            }),
            { questionType: "text" }
          );
          if (ticketPurchaserQuestions)
            purchaserQuestions.push(...ticketPurchaserQuestions);

          // Spartan Foundation Question
          if (spartanFoundationQuestionId) {
            spartanFoundationQuestions.push(
              ...filter(purchaser_questions?.purchaserQuestions, {
                questionId: spartanFoundationQuestionId,
              })
            );
          }

          // Addons
          let addonTicket = ticket.tags?.includes("ADDON");
          let addonsList = [];
          if (eventIds.includes(event.id) && !addonTicket) {
            // filter: get list of tickets with tag "addon"
            // map: modify list, add selectedQuantity based on storedTickets data
            // find: get (if exist) addon from storedTickets
            addonsList = map(
              filter(ticketType.ticket_types, (addon) => {
                let tags = map(addon.tags, (tag) => toUpper(tag));
                return tags.includes("ADDON");
              }),
              (addonData) => {
                let cartAddonData = find(storedTickets, {
                  ts_ticket_type_id: +addonData.id,
                });
                return {
                  ...addonData,
                  tags: map(addonData.tags, (tag) => toUpper(tag)),
                  selectedQuantity: cartAddonData ? cartAddonData.quantity : 0,
                };
              }
            );
            // Remove event ID.
            // Reason - hide addons for same event ID in checkout list
            let elIndex = eventIds.indexOf(event.id);
            eventIds.splice(elIndex, 1);
          }

          // Passes
          const passTags = ["T-PASS", "NT-PASS"];
          let passTicket = some(passTags, (tag) => includes(ticket.tags, tag));
          let user,
            attendeeAddress = {
              address: "",
              address2: "",
              city: "",
              state: "",
              zip: "",
              country: "",
            };
          // fill pass fields
          if (cartTicket.attendeeAddress)
            attendeeAddress = cartTicket.attendeeAddress;

          if (passTicket) {
            user = {
              // Non editable fields
              firstName: userData.first_name || "",
              lastName: userData.last_name || "",
              email: userData.email || "",
              // Editable fields
              mobilePhone: userData.mobile_phone || "",
              mobilePhoneParsed: userData.mobile_phone_parsed || {},
              gender: userData.gender || "",
              birthDate: userData.birth_date || "",
              birthDateParsed: {
                month: toNumber(userData?.birth_date?.split("-")[1]) || "",
                day: toNumber(userData?.birth_date?.split("-")[2]) || "",
                year: toNumber(userData?.birth_date?.split("-")[0]) || "",
              },
            };
          }
          // NT-PASS quantity validation
          let ntPass = includes(ticket.tags, "NT-PASS");
          if (ntPass && cartTicket.quantity > 1) {
            ctx.dispatch("updateTicketsListItem", {
              ticket: {
                ts_event_id: cartTicket.ts_event_id,
                ts_ticket_type_id: cartTicket.ts_ticket_type_id,
                country: cartTicket.country,
              },
              quantity: 1,
            });
          }

          return {
            event,
            waiver,
            customerQuestions,
            taxesQuestions,
            // TODO: Use only required fields
            ...ticket,
            // Contains params for Vue component functionality
            accountTicketCard: {
              id: toNumber(ticket.id),
              //
              collapsed: cartTicket.collapsed || false,
              valid: cartTicket.valid || true,
              completed: cartTicket.completed || false,
              // payload data
              quantity: ntPass ? 1 : cartTicket.quantity,
              waveTimeId: cartTicket.waveTimeId,
              signature: cartTicket.signature,
              // for NT-PASS & T-PASS ticket tags
              user,
              attendeeAddress,
            },
            addonTicket,
            addonsList,
            passTicket,
            addonQuestions: filter(
              ticketAttendeeQuestions,
              (question) => question.memAddonType === "question"
            ),
            addonComplexQuestions: filter(
              ticketAttendeeQuestions,
              (question) => question.memAddonType === "complexQuestion"
            ),
          };
        });
        // Filter list by addons logic *
        // TODO: Add link to document
        let regularTicketsIds = map(
          filter(ticketsList, ["addonTicket", false]),
          "eventId"
        );

        // Exclude addons if regular ticket presents
        // Exclude Spartan+ ticket
        let filteredTicketsList = filter(ticketsList, (ticket) => {
          return (
            !ticket.spartanPlusTicket &&
            (!regularTicketsIds.includes(ticket.eventId) || !ticket.addonTicket)
          );
        });

        // TS Stripe data
        let stripeData = {
          customerId: result.stripe_customer,
          ...result.stripe_key,
        };

        ctx.commit("updateCheckoutList", {
          ticketsList: filteredTicketsList,
          purchaserQuestions: uniqBy(purchaserQuestions, "questionId"),
          stripe: stripeData,
          response: result,
        });

        // Spartan Foundation Question & Answer
        let foundationQuestion = head(spartanFoundationQuestions);
        if (foundationQuestion)
          ctx.commit("setSpartanFoundationQuestion", foundationQuestion);
        // Set default answer
        // if (foundationQuestion && foundationQuestion.defaultAnswer !== "0")
        //   ctx.commit(
        //     "setSpartanFoundationQuestionAnswer",

        //     find(foundationQuestion.questionAnswers, {
        //       id: foundationQuestion.defaultAnswer,
        //     })
        //   );

        // collapse first card
        let first = head(filteredTicketsList);

        if (
          filteredTicketsList.length === 1 ||
          (!first.accountTicketCard.completed && filteredTicketsList.length > 1)
        ) {
          ctx.commit("updateTicketCard", {
            ticketTypeId: first?.id,
            field: "collapsed",
            value: true,
          });
        }

        // fill extras step from cart
        if (cartDetails.extras) {
          ctx.commit(
            "setSpartanFoundationQuestionAnswer",
            cartDetails.extras.spartanFoundationQuestionAnswer
          );
          ctx.commit(
            "setCheckoutFundraisingCompany",
            cartDetails.extras.checkoutFundraisingCompany
          );
        }

        // TODO: Need to combine into single LS object

        // Payment step checkbox text \_(0_0)_/
        localStorage.setItem(
          "memPaymentCheckbox",
          result.settings?.termsCheckboxCustomLabel
        );
        localStorage.setItem(
          "checkoutCurrency",
          result.billing_settings.currencyType
        );
        localStorage.setItem(
          "checkoutStripeCustomerID",
          result.stripe_customer
        );
        localStorage.setItem("checkoutStripeData", JSON.stringify(stripeData));
      } catch (error) {
        console.log("Error", error);
      }
    },
    async getOrderDescribe(ctx, params = {}) {
      const logsColor = "red";
      console.log("%c Price decomposition request", `color: ${logsColor}`);

      let timeoutID = runWaitTimer(ctx);
      let {
        userData,
        checkoutRegion,
        checkoutList,
        checkoutPromoCode,
        spartanFoundationQuestionId,
      } = ctx.getters;

      if (checkoutList.length === 0) {
        try {
          await ctx.dispatch("getOrder");
          // add Refunt protect to payload
          if (ctx.getters.refoundProtectData.protectionSelected) {
            params.includeRefundProtect = true;
          }
        } catch (error) {
          console.log("Get TS order error: ", error);
          return Promise.reject(error);
        }
      }

      let tickets = await ctx.dispatch(
        "generateOrderPayload",
        "price-decomposition"
      );

      let payload = {
        basicInfo: {
          emailAddress: userData.email,
        },

        tickets,
      };
      // promo
      if (checkoutPromoCode.value && checkoutPromoCode.isValid)
        payload.promoCode = checkoutPromoCode.value;
      if (!payload.promoCode && localStorage.getItem("checkoutCoupon")) {
        let { code } = JSON.parse(localStorage.getItem("checkoutCoupon"));
        payload.promoCode = code;
      }
      // Add Spartan Foundation Question
      if (
        spartanFoundationQuestionId &&
        ctx.getters.spartanFoundationQuestionAnswer.answer
      ) {
        payload.purchaserQuestions = [
          {
            id: spartanFoundationQuestionId,
            answer: ctx.getters.spartanFoundationQuestionAnswer.answer,
          },
        ];
      }
      // refund protect
      if (
        params.includeRefundProtect &&
        ctx.getters.refoundProtectData.protectionSelected
      ) {
        payload.insuranceInfo = {
          optedIn: 1,
          price: `${ctx.getters.refoundProtectData.protectionValue}`,
          quote: ctx.getters.refoundProtectData.quoteId,
          productId: ctx.getters.refoundProtectData.productId,
          vendor: "refundProtect",
        };
      }

      const authorization = getCookie("user");
      const res = await fetch(
        `${API}/account/checkout/orders/describe?country_code=${checkoutRegion}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
          body: JSON.stringify(payload),
        }
      );
      clearTimeout(timeoutID);
      ctx.dispatch("handleToolbarNotification", {
        isVisible: false,
      });
      if (!res.ok) return await handleError(res, "get-order-describe");
      let result = await res.json();
      ctx.commit("updateOrderDescribe", result);
      return result;
    },
    async validateOrder(ctx) {
      let timeoutID = runWaitTimer(ctx);
      let { checkoutRegion, checkoutList } = ctx.getters;

      if (checkoutList.length === 0) {
        try {
          await ctx.dispatch("getOrder");
        } catch (error) {
          console.log("Validate order error: ", error);
          return Promise.reject(error);
        }
      }

      let tickets = await ctx.dispatch(
        "generateOrderPayload",
        "validate-order"
      );
      // console.log("validate-order");

      const authorization = getCookie("user");
      const res = await fetch(
        `${API}/account/checkout/orders/validate?country_code=${checkoutRegion}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
          body: JSON.stringify({ tickets }),
        }
      );
      clearTimeout(timeoutID);
      ctx.dispatch("handleToolbarNotification", {
        isVisible: false,
      });
      if (!res.ok) return await handleError(res);
      // let result = await res.json();
    },
    async postOrder(
      ctx,
      { canRetry, sezzleCardData, paymentProcessor, redirectUrl }
    ) {
      let timeoutID = runWaitTimer(ctx);
      await sleep(0);
      // validate order
      try {
        await ctx.dispatch("validateOrder");
      } catch (error) {
        clearTimeout(timeoutID);
        return Promise.reject(error);
      }

      let tickets = await ctx.dispatch("generateOrderPayload", "post-order");
      let svgSignature = await ctx.dispatch(
        "generateOrderPayload",
        "signature-list"
      );
      localStorage.removeItem("orderId");

      const authorization = getCookie("user");
      let {
        userData,
        checkoutParams,
        checkoutRegion,
        checkoutPromoCode,
        checkoutBillingAddress,
        spartanFoundationQuestionId,
        spartanFoundationQuestionAnswer,
        refoundProtectData,
      } = ctx.getters;
      let customerId = checkoutParams.stripe?.customerId;
      if (!customerId)
        customerId = localStorage.getItem("checkoutStripeCustomerID");

      let payload = {
        basicInfo: {
          emailAddress: userData.email,
        },
        stripe_customer: customerId,
        tickets,
        svgSignature,
        purchaserAnswers: map(checkoutParams.purchaserQuestions, (question) => {
          let { questionId, answerModel } = question;
          return { questionId, answerText: answerModel };
        }),
        sendApplicationFeeAmount: true,
        sezzle: sezzleCardData,
        card: sezzleCardData ? { sezzlePayment: true } : null,
      };
      // Payment Processor
      if (paymentProcessor === "PayU") {
        payload.payu = true;
        payload.payu_redirect_url = `${redirectUrl}?payment_processor=PayU`;
        // payload.success_url = `${redirectUrl}?payment_processor=PayU&payment_status=success`;
        // payload.failure_url = `${redirectUrl}?payment_processor=PayU&payment_status=failure`;
        // payload.failure_url = `https://account-staging.spartan.com/us/checkout?payment_processor=PayU&payment_status=failure`
      }

      // Billing address
      if (checkoutParams.requireFullBillingAddress)
        payload.basicInfo = {
          emailAddress: userData.email,
          ...checkoutBillingAddress,
        };
      // promo
      if (checkoutPromoCode.value && checkoutPromoCode.isValid)
        payload.promoCode = checkoutPromoCode.value;
      // Add Spartan Foundation Question
      if (
        spartanFoundationQuestionId &&
        spartanFoundationQuestionAnswer.answer
      ) {
        payload.purchaserQuestions = [
          {
            id: spartanFoundationQuestionId,
            answer: spartanFoundationQuestionAnswer.answer,
          },
        ];
      }
      // refound protect
      if (refoundProtectData.quoteId) {
        payload.insuranceInfo = {
          optedIn: refoundProtectData.protectionSelected ? 1 : 0,
          price: `${refoundProtectData.protectionValue}`,
          quote: refoundProtectData.quoteId,
          productId: refoundProtectData.productId,
          vendor: "refundProtect",
        };
      }

      const res = await fetch(
        `${API}/account/checkout/orders?country_code=${checkoutRegion}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
          body: JSON.stringify(payload),
        }
      );
      clearTimeout(timeoutID);
      ctx.dispatch("handleToolbarNotification", {
        isVisible: false,
      });

      if (!res.ok && !canRetry)
        return await handleError({}, "post-order-non-retryable");

      if (!res.ok) return await handleError(res, "post-order");

      let result = await res.json();

      if (!result.order?.id)
        return await handleError({}, "post-order-missing-id");

      ctx.commit("updateOrder", {
        ...result.order,
        stripePaymentIntent: result.stripe_payment_intent,
        payuForm: result.payu_payment?.form_data,
      });
      localStorage.setItem("orderId", result.order?.id);
    },
    async completeOrder(ctx, paymentIntentId = "") {
      const authorization = getCookie("user");
      const orderId = localStorage.getItem("orderId");

      let timeoutID = runWaitTimer(ctx);
      let payload = { paymentIntentId };
      let { checkoutRegion, userData, cartDetails } = ctx.getters;
      // Fundraising company
      // null || object
      let fundraisingCompany = cartDetails?.extras?.checkoutFundraisingCompany;
      // object
      if (isEmpty(fundraisingCompany))
        fundraisingCompany = ctx.getters.checkoutFundraisingCompany;

      // US
      if (!isEmpty(fundraisingCompany) && checkoutRegion === "US") {
        payload.beneficiary_account_id =
          fundraisingCompany.beneficiaryAccountId;
        payload.event_campaign_id = fundraisingCompany.eventCampaingId;
        payload.wait_for_completion = fundraisingCompany.waitForCompletion;

        // givestar_charity_id
        // givestar_target_amount
      }
      // Other (GB)
      if (
        !isEmpty(fundraisingCompany) &&
        fundraisingCompany.total &&
        checkoutRegion !== "US"
      ) {
        payload.givestar_charity_id = fundraisingCompany.charityId;
        payload.givestar_target_amount = fundraisingCompany.total;
      }

      let res = await fetch(
        `${API}/account/checkout/orders/${orderId}/complete?country_code=${checkoutRegion}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
          body: JSON.stringify(payload),
        }
      );
      clearTimeout(timeoutID);
      ctx.dispatch("handleToolbarNotification", {
        isVisible: false,
      });
      ctx.dispatch("deleteCart");
      if (!res.ok) return await handleError(res);
      let result = await res.json();

      ctx.commit("setCompleteOrderResponse", {
        responseDataLayer: result.dataLayer,
        tickets: result.tickets?.data,
        userData,
        fundraisingCompany,
      });
    },
    async getOrder(ctx) {
      let { checkoutRegion, storedTickets, spartanFoundationQuestionId } =
        ctx.getters;
      const authorization = getCookie("user");
      let orderId = localStorage.getItem("orderId");
      if (!orderId) return;
      let res = await fetch(
        `${API}/account/checkout/orders/${orderId}?country_code=${checkoutRegion}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
        }
      );
      if (!res.ok) return await handleError(res);
      let result = await res.json();
      try {
        let orderTickets = result?.order?.tickets?.data;
        // Filter Spartan Plus ticket
        const SPARTAN_PLUS_TICKET =
          config.checkout[checkoutRegion.toLowerCase()]?.spartanPlusTicket;
        if (SPARTAN_PLUS_TICKET)
          orderTickets = filter(
            orderTickets,
            (ticket) => ticket.typeId !== SPARTAN_PLUS_TICKET.ticketTypeId
          );
        // Fill Refund protect data
        let orderInsurance = result?.order?.insurance;
        if (orderInsurance && orderInsurance.quoteId) {
          ctx.commit("setRefunfProtectData", {
            protectionSelected: orderInsurance.optedIn === "1" ? true : false,
            protectionValue: orderInsurance.premium,
            quoteId: orderInsurance.quoteId,
            products: [{ productId: orderInsurance.productId }],
          });
        }
        const spartanFoundationQuestions = [];

        let tickets = map(groupBy(orderTickets, "typeId"), (ticketTypeList) => {
          // Check if ticket has Attendee question with price
          let addonQuestions = [];
          each(ticketTypeList, (ticket) => {
            let ticketAttendeeQuestions = filter(
              ticket.attendeeQuestions,
              (question) => {
                let amount = toNumber(question.addOnAmount);
                return amount && amount > 0;
              }
            );
            addonQuestions = [
              ...addonQuestions,
              ...map(ticketAttendeeQuestions, (question) => {
                return {
                  ...question,
                  selectedQuantity: 1,
                  memAnswer: question.answerText,
                  memPrice: toNumber(question.addOnAmount),
                };
              }),
            ];
          });

          let cartTicket = find(storedTickets, {
            ts_ticket_type_id: toNumber(ticketTypeList[0].typeId),
          });

          // Get Spartan Foundation Question
          if (spartanFoundationQuestionId) {
            spartanFoundationQuestions.push(
              ...filter(ticketTypeList[0]?.purchaserQuestions, {
                questionId: spartanFoundationQuestionId,
              })
            );
          }

          return {
            id: ticketTypeList[0].typeId,
            accountTicketCard: {
              quantity: ticketTypeList.length,
              waveTimeId: ticketTypeList[0].waveTime?.id,
            },
            event: cartTicket?.event,
            // 0_(0)/ Help
            addonQuestions: map(
              groupBy(addonQuestions, "questionId"),
              (groupedQuestions, questionId) => {
                return {
                  questionId,
                  ...groupedQuestions[0],
                  selectedQuantity: _.sumBy(
                    groupedQuestions,
                    "selectedQuantity"
                  ),
                };
              }
            ),
          };
        });

        let fundraisingCompany =
          ctx.getters.cartDetails?.extras?.checkoutFundraisingCompany;

        if (fundraisingCompany)
          ctx.commit("setCheckoutFundraisingCompany", fundraisingCompany);

        // Spartan Foundation Question & Answer
        let foundationQuestion = head(spartanFoundationQuestions);
        if (foundationQuestion)
          ctx.commit("setSpartanFoundationQuestionAnswer", {
            ...foundationQuestion,
            answer: foundationQuestion.answerText,
          });

        ctx.commit("updateCheckoutList", { ticketsList: tickets });
      } catch (error) {
        console.log(error);
        return await handleError({ status: 666, error });
      }
    },
    async removeTicket(ctx, ticket) {
      // removeCheckoutTicket(?)
      let { checkoutRegion } = ctx.getters;
      let { id, addonsList } = ticket;

      let addons = filter(addonsList, (addon) => addon.selectedQuantity > 0);
      // remove ticket addons
      // remove ticket addons from cart
      if (addons.length !== 0)
        each(addons, async (addon) => {
          await ctx.dispatch("updateTicketsListItem", {
            ticket: {
              ts_event_id: toNumber(addon.eventId),
              ts_ticket_type_id: toNumber(addon.id),
              country: checkoutRegion,
            },
            quantity: 0,
          });
        });
      // remove ticket from state.list
      ctx.commit("removeCheckoutListItem", id);
      // remove orderId from localStorage, just in case;
      if (ctx.getters.checkoutList?.length === 0)
        localStorage.removeItem("orderId");
      // remove ticket from cart
    },
    async getStripePaymentMethods(ctx) {
      const authorization = getCookie("user");
      let { checkoutParams, checkoutRegion } = ctx.getters;
      let customerId = checkoutParams.stripe?.customerId;
      if (!customerId)
        customerId = localStorage.getItem("checkoutStripeCustomerID");

      const res = await fetch(
        `${API}/account/checkout/orders/stripe_payment_methods?country_code=${checkoutRegion}&stripeCustomerId=${customerId}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
        }
      );

      if (!res.ok) return await handleError(res);

      let result = await res.json();
      ctx.commit("setPaymentMethods", result.paymentMethods);
    },
    // applyPromoCode ???
    async validatePromoCode(ctx) {
      let { checkoutPromoCode, checkoutRegion } = ctx.getters;

      localStorage.removeItem("checkoutCoupon");

      const authorization = getCookie("user");
      const res = await fetch(
        `${API}/account/checkout/orders/validate_promo_code?country_code=${checkoutRegion}&promo_code=${checkoutPromoCode.value}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
        }
      );

      if (!res.ok) return await handleError(res, "validate-code");

      let result = await res.json();
      return result;
    },
    async generateOrderPayload(ctx, type) {
      // payload types:
      //  - price-decomposition (getOrderDescribe);
      //  - validate-order (validateOrder);
      //  - post-order (postOrder);
      //  - signature-list (postOrder).
      let { checkoutList, userData, checkoutRegion, orderSpartanPlusTicket } =
        ctx.getters;
      const membershipIdTags = ["T-PASS", "NT-PASS", "KIDS"];
      const SPARTAN_PLUS_TICKET =
        config.checkout[checkoutRegion.toLowerCase()]?.spartanPlusTicket;

      let spartanPlusTicket = {
        ticketTypeId: SPARTAN_PLUS_TICKET?.ticketTypeId,
        externalMembershipId: userData.id,
        attendeeQuestions: [],
        ticketClaim: {
          isClaim: "1",
          claimantEmailAddress: userData.email,
        },
      };

      const list = map(checkoutList, (ticket) => {
        // add addons
        let ticketAddons = map(ticket.addonsList, (addon) => {
          return {
            ticketTypeId: addon.id,
            quantity: addon.selectedQuantity,
            attendeeQuestions: [],
            //
            externalMembershipId: "null",
            ticketClaim: {
              isClaim: "1",
              claimantEmailAddress: userData.email,
            },
          };
        });
        let { user, attendeeAddress } = ticket.accountTicketCard;

        let useMembershipId = some(membershipIdTags, (tag) =>
          includes(ticket.tags, tag)
        );

        return {
          user,
          ticketTypeId: ticket.id,
          quantity: ticket.accountTicketCard.quantity,
          waveTimeId: ticket.accountTicketCard.waveTimeId,
          attendeeAddress,
          signature: ticket.accountTicketCard.signature,
          attendeeQuestions: map(ticket.customerQuestions, (question) => {
            let { questionId, answerId, answerText } = question;
            return { questionId, answerId, answerText };
          }),
          externalMembershipId: useMembershipId ? `${userData.id}` : "null",
          addons: ticketAddons,
          addonQuestions: map(ticket.addonQuestions, (question) => {
            return { ...question, systemCounter: question.selectedQuantity };
          }),
          addonComplexQuestions: map(
            ticket.addonComplexQuestions,
            (question) => {
              return { ...question, systemArray: [...question.memAnswers] };
            }
          ),
          // spartanTab: ticket.spartanTab,
          // photoPackageAddon: ticket.photoPackageAddon,
          // tShirtAddon: ticket.tShirtAddon,
          passTicket: ticket.passTicket,
          taxesQuestions: ticket.taxesQuestions,
        };
      });

      if (type === "validate-order") {
        return map(list, (ticket) => {
          let { ticketTypeId, quantity, waveTimeId } = ticket;
          return {
            ticketTypeId,
            quantity,
            waveTimeId,
          };
        });
      }
      if (type === "signature-list") {
        return map(list, (ticket) => {
          let { ticketTypeId, signature } = ticket;
          return {
            ticketTypeId,
            file: signature,
          };
        });
      }

      // generate full list
      let ticketsList = [];

      each(list, (ticket) => {
        // collapse event tickets
        times(ticket.quantity, () => {
          ticketsList.push(ticket);
        });

        // collapse event addons
        each(ticket.addons, (addon) => {
          times(addon.quantity, () => {
            ticketsList.push(addon);
          });
        });

        // ticketsList update: Add Spartan Tab, Photo Package & etc... questions (based on quantity)

        ticketsList = map(ticketsList, (listItem) => {
          if (listItem.ticketTypeId !== ticket.ticketTypeId) return listItem;

          let questions = listItem.attendeeQuestions;
          each(listItem.addonQuestions, (question) => {
            if (question.systemCounter > 0) {
              --question.systemCounter;
              let { questionId, memAnswer } = question;
              questions = [
                ...questions,
                {
                  answer: memAnswer,
                  id: questionId,
                },
              ];
            }
          });

          each(listItem.addonComplexQuestions, (question) => {
            if (question.systemArray.length !== 0) {
              let questionAnswer = head(question.systemArray);
              let { questionId, answer } = questionAnswer;
              questions = [
                ...questions,
                {
                  answer,
                  id: questionId,
                },
              ];
              question.systemArray.splice(0, 1);
            }
          });

          return { ...listItem, attendeeQuestions: questions };
        });
      });

      if (type === "price-decomposition") {
        let payload = map(ticketsList, (ticket) => {
          let { ticketTypeId, attendeeQuestions, taxesQuestions } = ticket;
          if (taxesQuestions)
            attendeeQuestions = [...attendeeQuestions, ...taxesQuestions];

          return {
            ticketTypeId,
            attendeeQuestions,
          };
        });
        // Add Spartan Plus ticket
        if (SPARTAN_PLUS_TICKET && orderSpartanPlusTicket)
          payload.push(spartanPlusTicket);

        return payload;
      }
      // post-order
      let payload = map(ticketsList, (ticket) => {
        let { user, attendeeAddress, attendeeQuestions, taxesQuestions } =
          ticket;
        let ticketPayload = {
          ticketTypeId: ticket.ticketTypeId,
          externalMembershipId: ticket.externalMembershipId,
          waveTimeId: ticket.waveTimeId,
          attendeeQuestions,
        };

        if (taxesQuestions) {
          ticketPayload.attendeeQuestions = [
            ...attendeeQuestions,
            ...taxesQuestions,
          ];
        }

        // NT-PASS & T-PASS
        if (!ticket.passTicket)
          ticketPayload = {
            ...ticketPayload,
            ticketClaim: {
              isClaim: "1",
              claimantEmailAddress: userData.email,
            },
          };

        // NT-PASS & T-PASS
        if (user)
          ticketPayload = {
            ...ticketPayload,
            partyMember: ticket.user.firstName,
            partyMemberLastName: ticket.user.lastName,
            partyMemberEmail: ticket.user.email,
            partyMemberBirthMonth: ticket.user.birthDateParsed.month,
            partyMemberBirthDay: ticket.user.birthDateParsed.day,
            partyMemberBirthYear: ticket.user.birthDateParsed.year,
            partyMemberGender: ticket.user.gender === "M" ? "Male" : "Female",
            partyMemberPhone: ticket.user.mobilePhone,
          };
        // NT-PASS & T-PASS
        if (attendeeAddress)
          ticketPayload = {
            ...ticketPayload,
            attendeeAddress: ticket.attendeeAddress,
          };
        return ticketPayload;
      });

      // Add Spartan Plus ticket
      if (SPARTAN_PLUS_TICKET && orderSpartanPlusTicket)
        payload.push(spartanPlusTicket);

      return payload;
    },
    async confirmPayuTransaction(ctx, orderId) {
      let { checkoutRegion } = ctx.getters;
      let timeoutID = runWaitTimer(ctx);
      const authorization = getCookie("user");
      const res = await fetch(
        `${API}/account/checkout/orders/confirm_payu_transaction?country_code=${checkoutRegion}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authorization,
          }),
          body: JSON.stringify({ orderId }),
        }
      );
      clearTimeout(timeoutID);
      ctx.dispatch("handleToolbarNotification", {
        isVisible: false,
      });

      if (!res.ok) return await handleError(res);

      let result = await res.json();

      if (!result.success)
        return await handleError({ title: result.reason }, "payu-transaction");

      return result;
    },
  },
  mutations: {
    updateCheckoutList: (state, payload) => {
      let { ticketsList, response, purchaserQuestions, stripe } = payload;
      state.list = ticketsList;
      if (!response) return;
      let { settings, billing_settings } = response;

      state.params = {
        ...settings,
        refundProtectActive:
          settings.refundProtectActive === "1" ? true : false,
        currency: billing_settings.currencyType,
        requireFullBillingAddress:
          billing_settings.requireFullBillingAddress === "1" ? true : false,
        stripe,
        purchaserQuestions,
      };
    },
    removeCheckoutListItem: (state, id) => {
      state.list = filter(state.list, (item) => item.id !== id);
    },
    setCheckoutRegion: (state, region) => {
      state.checkoutRegion = region;
    },
    updateOrderDescribe: (state, payload) => {
      state.orderDetails = payload;
    },
    updateTicketCard: (state, payload) => {
      const { ticketTypeId, field, value } = payload;
      if (!ticketTypeId) return;

      const ticket = find(state.list, { id: ticketTypeId });
      Vue.set(ticket.accountTicketCard, field, value);
    },
    updateOrder: (state, payload) => {
      state.order = payload;
    },
    setPaymentMethods: (state, payload) => {
      state.paymentMethods = payload;
    },
    setPromoCodeStatus: (state, isValid) => {
      state.promoCode.isValid = isValid;
    },
    deletePromoCode: (state) => {
      state.promoCode.value = "";
      state.promoCode.isValid = false;
      localStorage.removeItem("checkoutCoupon");
    },
    setCompleteOrderResponse: (state, payload) => {
      state.completeOrderResponse = payload;
    },
    setSpartanPlusTicket: (state, status) => {
      state.spartanPlusTicket = status;
    },
    setSpartanFoundationQuestion: (state, question) => {
      state.spartanFoundationQuestion = question;
    },
    setSpartanFoundationQuestionAnswer: (state, answer) => {
      state.spartanFoundationQuestionAnswer = answer;
    },
    setCheckoutFundraisingCompany: (state, company) => {
      state.checkoutFundraisingCompany = company;
    },
    resetCheckoutState: (state) => {
      let defaultState = {
        params: {},
        list: [],
        orderDetails: {},
        order: {},
        promoCode: {
          isValid: false,
          value: "",
        },
      };
      Object.assign(state, defaultState);
    },
    setRefunfProtectData: (state, data) => {
      let product = head(data?.products);
      state.refoundProtectData = data;
      state.refoundProtectData.productId = product?.productId;
    },
  },
  getters: {
    checkoutParams: (state) => state.params,
    checkoutRegion: (state) => toUpper(state.checkoutRegion),
    checkoutList: (state) => state.list,
    // TODO: Give more informative name
    orderDetails: (state) => state.orderDetails,
    orderData: (state) => state.order,
    paymentMethods: (state) => state.paymentMethods,
    checkoutPromoCode: (state) => state.promoCode,
    checkoutBillingAddress: (state) => state.billingAddress,
    gtmConversionData: (state) => {
      let { responseDataLayer, tickets, userData, fundraisingCompany } =
        state.completeOrderResponse;
      let coupon = JSON.parse(localStorage.getItem("checkoutCoupon"));
      let transactionProducts = map(tickets, (ticket) => {
        return {
          sku: ticket.eventId,
          name: ticket.ticketTypeName,
          price: ticket.paidUponCheckout,
          basePrice: ticket.price,
          originalBasePrice: ticket.originalPrice,
          quantity: 1,
          eventTitle: ticket.eventName,
          typeId: ticket.typeId,
          typeSku: ticket.typeId,
          couponCode: coupon?.code || "",
          couponCodeAmount: ticket.couponCodeAmount || "",
          emailAddress: userData.email,
          ticketId: ticket.barcode,
          fee1Amount: ticket.fee1Amount,
          fee1Name: ticket.fee1Name,
          fee2Amount: ticket.fee2Amount,
          fee2Name: ticket.fee2Name,
          addOns: ticket.addOnsAmount,
        };
      });
      return {
        ...responseDataLayer,
        localCurrency: localStorage.getItem("checkoutCurrency"),
        fundraiseId: fundraisingCompany?.gtmId || "No",
        // tickets
        transactionProducts,
        // price decomposition
        orderTotal: state.orderDetails.total,
        transactionTotal: state.orderDetails.total,
        transactionTotalWithProcessing: state.orderDetails.totalWithProcessing,
        transactionServiceFee: state.orderDetails.serviceFee,
        transactionThirdPartyFee: state.orderDetails.thirdPartyFee,
        transactionProcessingFee: state.orderDetails.processingFee,
        transactionAllAddOns: state.orderDetails.addOnsTotal,
      };
    },
    orderSpartanPlusTicket: (state) => state.spartanPlusTicket,
    spartanFoundationQuestionId: (state) => {
      return config.checkout[state.checkoutRegion]?.spartanFoundationQuestionId;
    },
    spartanFoundationQuestion: (state) => state.spartanFoundationQuestion,
    spartanFoundationQuestionAnswer: (state) =>
      state.spartanFoundationQuestionAnswer,
    checkoutFundraisingCompany: (state) => {
      return state.checkoutFundraisingCompany;
    },
    refoundProtectData: (state) => state.refoundProtectData,
    // save selections
    checkoutBackup: (state) => {
      return {
        extras: {
          spartanFoundationQuestionAnswer:
            state.spartanFoundationQuestionAnswer,
          checkoutFundraisingCompany: state.checkoutFundraisingCompany,
        },
        tickets: map(state.list, (ticket) => {
          return {
            ...ticket.accountTicketCard,
            // "event" field is using in Refund protect logic
            event: {
              starts: ticket.event.starts,
              refund_protect_disable: ticket.event.refund_protect_disable,
            },
            addonQuestions: ticket.addonQuestions,
            addonComplexQuestions: ticket.addonComplexQuestions,
            customerQuestions: ticket.customerQuestions,
            purchaserQuestions: state.params.purchaserQuestions,
          };
        }),
      };
    },
  },
};
