import { KeyConverter } from "@/common/helpers";
import { getCookie, setCookie, setCookieV2 } from "@/cookie";
import { findIndex, isEmpty, forEach, toNumber } from "lodash";
import config from "@/config";

const API = config.apiGate;
const TICKETS_COOKIE_NAME = "express_tickets";

export default {
  async updateTicketAttendee(ctx, payload) {
    const { ticketId, form } = payload;
    const authorization = getCookie("user");
    const response = await fetch(`${API}/account/tickets/${ticketId}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authorization,
      }),
      body: KeyConverter.makeRequestData(form),
    });
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        data: await response.json(),
      });
    }
    return Promise.resolve();
  },
  async getTicketAttendees(ctx, payload) {
    ctx.commit("setAttendees", []);
    const authorization = getCookie("user");
    const isKid = !!payload?.isKid;
    const response = await fetch(`${API}/account/tickets/attendees`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authorization,
      }),
    });
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        data: await response.json(),
      });
    }
    const responseData = await response.json();
    const result = responseData?.athlete_users?.filter(
      (item) => item.is_kids === isKid
    );
    ctx.commit("setAttendees", result);
    return Promise.resolve(responseData);
  },
  async updateTicketRecipient(ctx, payload) {
    const { barcode, model } = payload;
    const authorization = getCookie("user");

    ctx.commit("setTicketProcessing", { barcode, status: true });

    const response = await fetch(
      `${API}/account/tickets/${barcode}/change_recipient`,
      {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
        body: JSON.stringify(model),
      }
    );
    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      ctx.commit("setTicketProcessing", { barcode, status: false });
      return Promise.reject("Update ticket recipient error");
    }
    ctx.commit("setCurrentTicket", null);
  },
  async assignTeamToTickets(ctx, payload) {
    const authorization = getCookie("user");
    const response = await fetch(`${API}/account/tickets/assign_team`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authorization,
      }),
      body: JSON.stringify(payload),
    });

    let { eventData } = ctx.rootGetters;
    await ctx.dispatch("getEvent", eventData.id);

    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject("Add ticket team error");
    }
  },
  async deleteTicketTeam(ctx, barcode) {
    const authorization = getCookie("user");
    const response = await fetch(
      `${API}/account/tickets/${barcode}/remove_team`,
      {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      }
    );

    let { eventData } = ctx.rootGetters;
    await ctx.dispatch("getEvent", eventData.id);

    if (!response.ok && response.status === 500)
      ctx.dispatch("handleToolbarNotification");
    if (!response.ok) {
      return Promise.reject("Delete ticket team error");
    }
  },
  async getInviteTeamInfo(ctx, payload) {
    const { event_id, team_id } = payload;
    const authorization = getCookie("user");
    const response = await fetch(
      `${API}/account/teams/invite_to_race?event_id=${event_id}&team_id=${team_id}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      }
    );
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        // data: await response.json()
      });
    }
    let teamInfo = await response.json();
    ctx.commit("setInviteTeamInfo", teamInfo);
  },

  // --- cookie tickets actions (legacy) ---
  // get tickets from cookie
  getCookieTickets(ctx) {
    let tickets = getCookie(TICKETS_COOKIE_NAME);
    if (tickets) ctx.commit("setStoredTickets", JSON.parse(tickets));
  },
  // create/update/delete cookie tickets list
  setCookieTickets(ctx, tickets = []) {
    // delete cookie tickets
    if (isEmpty(tickets)) {
      ctx.commit("setStoredTickets", []);
      setCookieV2(TICKETS_COOKIE_NAME, "", { "max-age": -1 });
      return;
    }

    let { storedTickets } = ctx.state;
    // create cookie tickets
    if (isEmpty(storedTickets)) {
      ctx.commit("setStoredTickets", tickets);
      setCookie(TICKETS_COOKIE_NAME, JSON.stringify(tickets), 7);
      return;
    }

    // check storedTickets country
    let storedTicketsCountry = storedTickets[0].country;
    let newTicketsCountry = tickets[0].country;
    // rewrite stored tickets
    if (storedTicketsCountry !== newTicketsCountry) {
      ctx.commit("setStoredTickets", tickets);
      setCookie(TICKETS_COOKIE_NAME, JSON.stringify(tickets), 7);
      return;
    }

    // update cookie tickets
    forEach(tickets, (ticket) => {
      let { tsEventId, tsTicketTypeId } = ticket;
      // check if ticket exist
      let ticketIndex = findIndex(storedTickets, {
        tsEventId,
        tsTicketTypeId,
      });
      if (ticketIndex >= 0) {
        storedTickets.splice(ticketIndex, 1, {
          tsEventId,
          tsTicketTypeId,
          country: ticket.country,
          quantity:
            toNumber(storedTickets[ticketIndex].quantity) +
            toNumber(ticket.quantity),
        });
      } else {
        storedTickets.push(ticket);
      }
    });
    ctx.commit("setStoredTickets", storedTickets);
    setCookie(TICKETS_COOKIE_NAME, JSON.stringify(storedTickets), 7);
  },
  // update/remove/add current ticket from cookie
  updateCookieTicket(ctx, { ticket, quantity, country }) {
    let { tsEventId, tsTicketTypeId } = ticket;
    let { storedTickets } = ctx.state;
    let ticketIndex = findIndex(storedTickets, {
      tsTicketTypeId,
      tsEventId,
    });

    // remove
    if (quantity === 0) {
      storedTickets.splice(ticketIndex, 1);
      // delete cookie if list empty
      if (isEmpty(storedTickets)) return ctx.dispatch("setCookieTickets", []);
    }
    // update
    if (quantity > 0 && storedTickets[ticketIndex]) {
      storedTickets[ticketIndex].quantity = quantity;
      storedTickets[ticketIndex].eventName = ticket.eventName;
      storedTickets[ticketIndex].spTicketTypeName = ticket.spTicketTypeName;
    }
    // add
    if (quantity > 0 && !storedTickets[ticketIndex]) {
      storedTickets.push({ ...ticket, country, quantity });
    }

    ctx.commit("setStoredTickets", storedTickets);
    setCookie(TICKETS_COOKIE_NAME, JSON.stringify(storedTickets), 7);
  },
  // --- ---

  async createZDTicket(ctx, payload) {
    const fullName = `${ctx.rootState.profile.user.first_name} ${ctx.rootState.profile.user.last_name}`;
    const event = ctx.rootState.events.event;
    if (!event) return Promise.reject(new Error("Undefined event"));
    const tickets = event.tickets
      .filter((item) => payload.tickets.indexOf(item.id) >= 0)
      .map((item) => ({
        ...item,
        full_name: item.is_claimed
          ? `${item.attendee?.first_name} ${item.attendee?.last_name}`
          : "Unclaimed",
      }));
    const ticketHtmlList = tickets.map(
      (item) =>
        `
        <ul style="list-style: none; padding-left: 0">
            <li>Barcode: ${item.barcode}</li>
            <li>TS Order number: ${item.order_number}</li>
            <li>Athlete: ${item.full_name}</li>
            <li>Heat: ${item.reg_choice_name}</li>
        </ul>
      `
    );
    const ticketData = {
      ticket: {
        subject: `Spartan+ Tickets Protection Refund Request from ${fullName}`,
        status: "new",
        type: "question",
        comment: {
          html_body: `
            <h3 style="margin-bottom: 20px;">Spartan+ Tickets Protection Refund request for the below tickets:</h3>
            <ul style="list-style: none;  padding-left: 0">
              <li><b>Event:</b><br/><span>${event.id} ${event.name}</span></li>
              <li><b>Tickets:</b></li>
              <li style="margin-bottom: 20px;">
                <ol>
                  ${ticketHtmlList.map((item) => `<li>${item}</li>`).join(" ")}
                </ol>
              </li>
            </ul>
          `,
        },
        requester: {
          name: fullName,
          email: ctx.rootState.profile.user.email,
        },
        tags: ["spartan_ticket_protection"],
      },
    };
    const authorization = getCookie("user");
    const response = await fetch(`${API}/account/zendesk/tickets`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authorization,
      }),
      body: KeyConverter.makeRequestData(ticketData),
    });
    if (!response.ok) {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        data: response.json(),
      });
    }
    return Promise.resolve();
  },
};
