import client from "@/services/contentfulClient";

function simplifyContentfulData(entry) {
  if (!entry || typeof entry !== "object") return entry;

  // Если объект содержит `fields`, извлекаем их
  if (entry.fields) {
    const simplified = {};
    for (const key in entry.fields) {
      simplified[key] = simplifyContentfulData(entry.fields[key]);
    }
    return simplified;
  }

  // Если это массив, обрабатываем каждый элемент
  if (Array.isArray(entry)) {
    return entry.map(simplifyContentfulData);
  }

  return entry;
}

export default {
  state: {},
  actions: {
    async getContentfulEntry(ctx, entryId) {
      try {
        const entry = await client.getEntry(entryId, {
          include: 2,
        });
        let list = simplifyContentfulData(entry.fields.components);
        return list;
      } catch (error) {
        return Promise.reject({ type: "contentful", error });
      }
    },
  },
  mutations: {},
  getters: {},
};
