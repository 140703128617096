import { find, filter } from "lodash";

import config from "@/config";
import { getCookie } from "@/cookie";

const API = config.apiAccount;

export default {
  state: {
    notificationPost: null,
    nextPage: true,
  },
  actions: {
    async getFeed(ctx, page) {
      const authorization = getCookie("user");
      const res = await fetch(`${API}/feeds?page=${page}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authorization,
        }),
      });
      if (!res.ok && res.status === 500)
        ctx.dispatch("handleToolbarNotification");
      if (!res.ok) {
        console.log("Feed error");
        return Promise.reject({ status: res.status, text: "Unauthorized" });
      }
      const feed = await res.json();
      ctx.commit("setNextPage", feed.next);

      // get notification post
      let notificationPost = find(feed.feeds, { template: "notification" });
      if (notificationPost) ctx.commit("setNotificationPost", notificationPost);

      let filteredPosts = filter(
        feed.feeds,
        (post) => post.template !== "notification"
      );
      return filteredPosts;
    },
  },
  mutations: {
    setNotificationPost(state, post) {
      state.notificationPost = post;
    },
    setNextPage(state, status) {
      state.nextPage = status;
    },
  },
  getters: {
    feedNotification(state) {
      return state.notificationPost;
    },
    feedNextPage(state) {
      return state.nextPage;
    },
  },
};
